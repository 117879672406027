/** @jsxImportSource theme-ui */

import { Box, Button, Container, Heading, Grid } from 'theme-ui'
import { Link } from 'react-router-dom'

// TODO: CHECK THESE LINKS TO MAKE SURE THEY ARE CORRECT
const CATEGORY_LINKS = [
  {
    name: 'ammuntion',
    url: '/category/ammunition',
  },
  {
    name: 'holsters',
    url: 'category/holsters-belts',
  },
  {
    name: 'optics',
    url: '/category/optics',
  },
  {
    name: 'bags & cases',
    url: '/category/gun-safes-storage',
  },
  {
    name: 'range gear',
    url: '/category/shooting-gear',
  },
  {
    name: 'cleaning tools',
    url: '/category/cleaning',
  },
  {
    name: 'gun parts',
    url: '/category/gun-parts',
  },
  {
    name: 'all',
    url: '/shop',
  },
]

const CategoryNavigation = props => {
  return (
    <Box as="section" sx={{ position: 'relative' }}>
      <Container sx={{ pt: [12, null, 24], pb: [8, null, 12] }}>
        <Box sx={{ filter: 'drop-shadow(0px 4px 4px rgba(0,0,0,0.15))' }}>
          <Button
            as="span"
            sx={{
              width: ['100%', null, '60%'],
              display: 'block',
              mx: 'auto',
              pointerEvents: 'none',
            }}
          >
            <Heading sx={{ fontSize: ['24px', null, null, '32px'] }}>Shop by category</Heading>
          </Button>
        </Box>
        <Grid
          sx={{
            mt: 12,
            gridTemplateColumns: ['1fr 1fr', null, 'repeat(auto-fill, minmax(240px, 1fr))'],
            gap: 4,
            '& > div:nth-of-type(5)': {
              display: ['none', null, 'block'],
            },
            '& > div:last-of-type': {
              gridColumn: ['1/-1', null, 'auto'],
            },
          }}
        >
          {CATEGORY_LINKS.map((link, index) => (
            <Box key={index} sx={{ filter: 'drop-shadow(0px 4px 4px rgba(0,0,0,0.15))' }}>
              <Link to={link.url}>
                <Button as="span" variant="categoryNavigation">
                  {link.name}
                </Button>
              </Link>
            </Box>
          ))}
          {/* <Button
            variant="categoryNavigation"
            sx={{
              bg: 'tertiary',
              color: 'textWhite',
              gridColumn: ['1/-1', null, 'auto'],
            }}
          >
            All
          </Button> */}
        </Grid>
      </Container>
    </Box>
  )
}

export default CategoryNavigation
