/** @jsxImportSource theme-ui */
import { Flex, Box } from 'theme-ui'

import Twitter from '../../assets/images/icons/twitter.svg'
// import Twitter from 'public/icons/twitter.svg'
import Facebook from '../../assets/images/icons/facebook.svg'
import YouTube from '../../assets/images/icons/youtube.svg'
import Instagram from '../../assets/images/icons/instagram.svg'

const SocialList = () => {
  return (
    <Flex as="ul" sx={{ width: '100%', listStyle: 'none', m: 0, p: 0, li: { mr: 4 } }}>
      <Box as="li">
        <a href="http://twitter.com/ammoplanetusa" target="_blank" rel="noreferrer nofollow">
          <img src={Twitter} alt="Twitter" />
        </a>
      </Box>
      {/* <Box as="li">
        <a href="#">
          <img src={YouTube} alt="YouTube" />
        </a>
      </Box> */}
      <Box as="li">
        <a href="https://www.facebook.com/Ammoplanet-104495984994900" target="_blank" rel="noreferrer nofollow">
          <img src={Facebook} alt="Facebook" />
        </a>
      </Box>
      <Box as="li">
        <a href="http://instagram.com/ammoplanetusa/" target="_blank" rel="noreferrer nofollow">
          <img src={Instagram} alt="Instagram" />
        </a>
      </Box>
    </Flex>
  )
}

export default SocialList
