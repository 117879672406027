const TermsAndConditions = () => {
  return (
    <>
      <h1>Terms &amp; Conditions</h1>
      <h1>AMMOPLANET</h1>
      <h2>TERMS &amp; CONDITIONS</h2>
      <p>Thank you for visiting Ammoplanet.com (the “Site”). The Company provides the content and materials on this Site as an information service. By accessing and using the Site, you are agreeing to be legally bound by and comply with the terms contained in this Terms of Use Agreement (“Agreement”). If you do not agree to these terms, you should refrain from viewing information on or obtaining documents from this Site.</p>
      <h3>1. GENERAL.</h3>
      <p>By using the Site, you, the user of the Site (“User” or “you” or “Customer”), are subject to these Terms of Use. User may not use the Site for any unlawful purpose. The Company may change the terms in this Agreement at any time. The changes will appear in this Agreement, and User’s use of the Site after the posting of any changes will constitute User’s agreement to the modified terms. Therefore, User should read these Terms of Use each time before User accesses the Site. User may print a copy of the Terms of Use Agreement for reference.</p>
      <p>You must be 18 years of age or older to access, visit, and use this website. You represent that you are at least 18 years of age, and that you have the legal right to use this website, to enter into this agreement, and to be bound by these terms and conditions for use of our website. You agree that you will use our website only for personal, noncommercial, informational, and lawful purposes.</p>
      <p>
        It is your responsibility to ascertain and obey all applicable local, state, federal and international laws (including minimum age requirements) regarding the possession, use and sale of any item purchased from the Ammoplanet Site. By placing an order, you represent that the order is lawful and will be used in a lawful and appropriate manner. In addition, you acknowledge that any goods sold in the U.S. may be subject to the import or export laws of the country in which the goods are sold. Accordingly, you agree to abide by all applicable export laws and regulations, including but not limited to the Export Administration Act and the Arms Export Control Act, and you represent and warrant that you will not transfer such goods to a foreign national or a foreign destination in violation
        of law.
      </p>
      <h3>2. MODIFICATIONS TO THE SITE.</h3>
      <p>The Company has the right at any time, for any reason and in its sole discretion to take down, interrupt, discontinue, alter, amend, or modify its website in whole or in part or terminate or limit User’s use of the website without notice. The Company shall have no liability to you whatsoever in connection with such actions or the availability of our website.</p>
      <p>The Content may contain errors or omissions. In the event that you are dissatisfied in any respect with the Content or The Company’s website, your exclusive remedy shall be discontinuance of use of our website. The Company shall have no liability in connection with any such errors or omissions.</p>
      <h3>3. COPYRIGHT PROTECTION.</h3>
      <p>The content displayed on the Site (“Content”) is the registered property of The Company or its licensors and is protected by U.S. and international copyright and other intellectual property laws.</p>
      <h3>4. CONTENT USE LIMITATIONS.</h3>
      <p>
        User agrees not to copy, reproduce, modify, display, perform, publish, create derivative works from, or store any Content on the Site. Users may not post any Content from the site to any social media, forums, newsgroups, mail lists, electronic bulletin boards, or other web sites, without the prior written consent of The Company. You are prohibited from posting on or transmitting through the Ammoplanet Site any material or content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually explicit, profane, hateful, or offensive, or that is otherwise objectionable, including, but not limited to, any material or content encouraging conduct that may constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable
        local, state, national, or international law. Ammoplanet reserves the right to remove any postings or content in violation of this Agreement.
      </p>
      <h3>5. TRADEMARKS.</h3>
      <p>The trademarks, service marks, brand names, and logos appearing on the Ammoplanet Site (“Marks”) are owned or licensed by Ammoplanet or by respective owners (Third Parties). Nothing herein shall be construed as granting any license or right to use the Marks without the express written permission of the owner. You may not frame or utilize framing techniques to enclose any Marks or use any meta tags or any other “hidden text” utilizing the Marks without the express written consent of Ammoplanet or Third Party Owner(s).</p>
      <h3>6. THIRD-PARTY INFORMATION, PRODUCTS, SERVICES AND WEBSITES</h3>
      <p>Our website may contain information about and advertisements for third-party businesses, products and services, hyperlinks to third-party sites and/or information or material authored by third parties. This is provided solely as an informational service to users. The Company does not sponsor, endorse, or recommend any such third-party business, products, services, or websites. The Company has no responsibility or liability in connection with your use or any third-party material or information, or access to any third-party website. It is your responsibility to determine the bona fides of all such third parties and the advisability of accessing their websites and/or dealing with them.</p>
      <h3>7. DISCLAIMER OF WARRANTIES.</h3>
      <p>
        Although The Company strives to provide high quality Content, The Company does not guarantee or warrant the accuracy, completeness or timeliness of the Site Content. USER EXPRESSLY ACKNOWLEDGES AND AGREES THAT USE OF THE INFORMATION FROM THE SITE IS AT USER’S SOLE RISK. THE CONTENT IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. The Company EXPRESSLY DISCLAIMS TO THE USER ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON- INFRINGEMENT. The Company MAKES NO WARRANTY THAT THE CONTENT WILL MEET USER’S REQUIREMENTS, OR THAT ACCESS TO THE SITE AND THE CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE; NOR DOES The Company MAKE ANY WARRANTY AS TO THE
        RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE OR AS TO THE ACCURACY OR RELIABILITY OF ANY CONTENT. USER UNDERSTANDS AND AGREES THAT ANY DOCUMENT AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THIS INFORMATION SERVICE IS DONE AT USER’S OWN DISCRETION AND RISK AND THAT USER IS SOLELY RESPONSIBLE FOR ANY DAMAGE TO USER’S COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA. The Company MAKES NO WARRANTY REGARDING ANY INFORMATION OBTAINED FROM ANY HYPERLINKED THIRD-PARTY SITE. NO INFORMATION OBTAINED BY USER FROM THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO USER.
      </p>
      <h3>8. LIMITATION OF LIABILITY.</h3>
      <p>
        BY USING The Company’s WEBSITE, YOU ACKNOWLEDGE AND AGREE THAT NEITHER The Company NOR ANY OF ITS SUBSIDIARIES, PARENT COMPANIES, AFFILIATES, LICENSORS, PARTNERS, MANAGERS, SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, OR OTHER AGENTS SHALL HAVE ANY RESPONSIBILITY OR LIABILITY TO ANY USER OR ITS PRINCIPALS OR AGENTS FOR ANY DAMAGES, WHETHER GENERAL, SPECIAL, DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL, INCLUDING ANY LOST PROFITS OR LOST SAVINGS, WHETHER OR NOT FORESEEABLE AND WHETHER BASED ON CONTRACT, TORT, COMMON LAW OR ANY OTHER THEORY OF LIABILITY, STATUTE OR REGULATION. UNDER NO CIRCUMSTANCE WILL The Company BE RESPONSIBLE FOR ANY INFORMATION CONTAINED IN, DISPLAYED ON OR OMITTED FROM OUR WEBSITE, FOR ANY USER’S RELIANCE ON ANY SUCH INFORMATION, WHETHER OR NOT CORRECT,
        CURRENT OR COMPLETE, OR FOR THE CONSEQUENCES OF ANY ACTION USER OR ANY OTHER PERSON TAKES OR FAILS TO TAKE BASED ON INFORMATION PROVIDED BY OR AS A RESULT OF THE USE OF OUR WEBSITE. FURTHERMORE, IN NO EVENT SHALL The Company BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, RESULTING FROM THE USE OR THE INABILITY TO USE THE SITE, OR FROM ANY GOODS OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH THE SITE OR RESULTING FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF TRANSMISSIONS OR DATA, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, USE, DATA OR OTHER INTANGIBLE PROPERTY, EVEN IF The Company HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT The Company IS
        PROVIDING ACCESS TO OUR WEBSITE IN RELIANCE ON THE DISCLAIMERS AND LIMITATIONS SET FORTH IN THIS AGREEMENT, AND FURTHER ACKNOWLEDGES AND AGREES THAT SUCH LIMITATIONS ARE REASONABLE. IN NO EVENT SHALL The Company’s LIABILITY TO USER EXCEED ANY FEES ACTUALLY PAID BY YOU FOR USE OF THE WEBSITE. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
      </p>
      <p>__(a)__It is the sole and complete responsibility of Customer to comply with all federal registration and transfer requirements, as well as any laws in their State of residence regarding the possession and registration of ammunition. The Company will not be liable under any circumstances for failure of Customer to comply with or qualify under law for the receipt and possession of ammunition. The Company will not under any circumstances accept a return from a Customer’s dealer for failure to comply with or qualify under the applicable firearms laws for possession and transfer of ammunition. The Company, in its absolute and sole discretion, will consider repurchase agreements under such circumstances at wholesale market value; but is under no obligation to do so.</p>
      <h3>9. USER INPUT.</h3>
      <p>All electronic mail or communications provided by User to The Company may be used by The Company for any purpose without compensation to the contributor, subject to The Company's web site privacy policy.</p>
      <h3>10. APPLICABLE LAW.</h3>
      <p>
        User acknowledges that the Content contained in this Site is controlled in and originates from the United States. The Company makes no representation that any of the Content is appropriate or available for use in other locations. The Company has no responsibility for any access to this web site from territories where the Content may be illegal or otherwise prohibited, including complying with the privacy laws of those territories or jurisdictions. Users who choose to access this Site from other locations do so at their own risk and are responsible for compliance with applicable local laws. Any claim relating to the use of Site and any Content shall be governed by the internal substantive laws of the State of Texas, without regard to its conflicts of laws rules. User expressly
        consents to the jurisdiction of the state and federal courts of Texas for any such claim.
      </p>
      <h3>11. PRIVACY AND SECURITY OF USER INFORMATION</h3>
      <p>The Company takes reasonable and appropriate steps to maintain the privacy and security of user information but cannot guarantee the security of such information in all contexts. Except as required by law, The Company assumes no responsibility or liability for disclosure or release of personal information. The Company assumes no responsibility or obligation for maintaining any information provided by users. Please see The Company’s Privacy Policy.</p>
      <h3>12. INDEMNIFICATION</h3>
      <p>You agree to indemnify, hold harmless, and defend Ammoplanet, its parents, subsidiaries, related companies, affiliates, directors, officers, employees, successors, assigns, contractors, service providers and agents from and against any action, cause, claim, damage, debt, demand or liability, including reasonable costs and attorney’s fees, asserted by any person, arising out of, or relating to: (i) this Agreement; (ii) your use of a Ammoplanet Site, including any data or work transmitted or received by you; and (iii) any prohibited use of a Ammoplanet Site..</p>
      <h3>13. SEVERABILITY</h3>
      <p>If any provision of these terms and conditions is held to be unlawful, void, invalid or unenforceable for any reason, that provision shall be deemed severable from these terms and conditions and shall not affect the validity and enforceability of any remaining provisions. In such event, the remainder of these terms and conditions shall continue in full force and effect, and the provision held to be invalid or unenforceable shall be automatically amended to most closely approximate the original provision on terms that are valid and enforceable and the court or other adjudicating authority holding such provision invalid or unenforceable shall make such amendment accordingly.</p>
      <h3>14. AMENDMENTS AND MODIFICATIONS</h3>
      <p>The Company may amend and modify these terms and conditions from time to time by posting changes on our website. You agree to review these terms and conditions prior to your use of our website and to comply with any revisions. Any use of the website after our posting of any such changes shall constitute your acceptance.</p>
      <h3>15. RESERVATION OF RIGHTS</h3>
      <p>ANY RIGHTS NOT EXPRESSLY GRANTED TO YOU IN ARE RESERVED BY The Company.</p>
      <h3>16. EXPORT CONTROLS</h3>
      <p>This company complies with all aspects of the International Traffic in Arms Regulations and the Export Administration Regulations. You are hereby put on notice that certain products we offer may be subject to U.S. Government export control laws and regulations, including, but not limited to, the International Traffic in Arms Regulations (ITAR) at Title 22 of the Code of Federal Regulations Parts 120-130 and the Export Administration Regulations (EAR); in which case, the transfer of its contents to a Foreign Person without first obtaining the appropriate U.S. Government approvals, may be prohibited by law.</p>
      <h3>17. SHIPPING INSURANCE</h3>
      <p>Ammo Planet is not liable for any physical damage to parcels or freight during transit, including theft or non-delivery, and buyers assume all risks for uninsured products.</p>
    </>
  )
}

export default TermsAndConditions
