/** @jsxImportSource theme-ui */
// import { LogoInverse } from 'config/logo'
import { Box, Container, Flex, Heading } from 'theme-ui'

import Logo from '../assets/images/logo-wide.svg'

const ColorSection = ({ title, content, children, className, showImage }) => {
  return (
    <Flex className={className} sx={{ bg: 'secondary', color: 'white' }} as="section">
      <Container
        sx={{
          zIndex: 2,
          position: 'relative',
          py: 16,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: ['wrap', null, null, 'nowrap'],
          }}
        >
          <Box sx={{ maxWidth: '80ch' }}>
            <Heading
              as="h1"
              variant="hero"
              sx={{
                color: 'white',
                textTransform: 'uppercase',
                lineHeight: 1.3,
              }}
            >
              {title}
            </Heading>
            <div sx={{ maxWidth: '64ch' }}>
              {content && (
                <div
                  sx={{
                    color: 'white',
                    p: { variant: 'text.paragraph' },
                  }}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
              {children && <>{children}</>}
            </div>
          </Box>
          {/* <Box sx={{ flex: "1 auto" }}> */}

          <div
            sx={{
              width: [250, null, 400],
              maxWidth: '100%',
              mt: [12, null, null, 0],
            }}
          >
            <img sx={{ width: '100%' }} alt="" src={Logo} />
          </div>
          {/* </Box> */}
        </Flex>
      </Container>
    </Flex>
  )
}

export default ColorSection
