/** @jsxImportSource theme-ui */

import axios from 'axios'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Button, Container, Flex, Heading, Input, Label, Paragraph, Spinner } from 'theme-ui'
import InputGroup from './InputGroup'
import ReactPhoneInput from './ReactPhoneInput'

import DesktopImage from '../assets/images/newsletter-gray-bg.png'
import MobileImage from '../assets/images/newsletter-gray-mobile-bg.png'

const formatPhoneNumber = value => {
  if (!value) return value
  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export const NewsletterBar = () => {
  const methods = useForm({
    defaultValues: {
      email: '',
      phone: '',
    },
  })
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = methods

  const emailSet = watch('email').length > 0
  const phoneSet = watch('phone').length > 0

  const onSubmit = async data => {
    try {
      await axios.post(`/api/klaviyo`, {
        email: data.email,
        phone: data.phone,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <aside
      sx={{
        pb: 0,
        position: 'relative',
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr',
      }}
    >
      {/* <div sx={{ position: "absolute", width: "100%", height: 438, bottom: 0 }}> */}
      <div
        sx={{
          gridRow: '1',
          gridColumn: 1,
          display: ['none', null, null, 'flex'],
          '>span': {
            width: '100% !important',
            height: '100% !important',
          },
        }}
      >
        <img
          src={DesktopImage}
          alt=""
          // objectFit="contain"
          // sx={{ maxHeight: "438px" }}
          width="100%"
          height="100%"
        />
      </div>
      <div
        sx={{
          gridRow: '1',
          gridColumn: 1,
          display: ['flex', null, null, 'none'],
          '>span': {
            width: '100% !important',
            height: '100% !important',
          },
          overflow: 'visible',
        }}
      >
        <img
          src={MobileImage}
          alt=""
          // layout="responsive"
          // objectFit="cover"
          // sx={{ maxHeight: "438px" }}
          // sx={{ width: "100%", height: "auto" }}
          width="100%"
          height="auto"
          // height={425.48}
          // objectFit="cover"
        />
      </div>
      {/* </div> */}

      {/* <div sx={{ position: "absolute", width: "100%", top: "50%" }}> */}
      <Container
        sx={{
          gridRow: 1,
          gridColumn: 1,
          display: 'flex',
          zIndex: 2,
          alignItems: 'center',
          py: [12, null, 16],
        }}
      >
        {isSubmitSuccessful ? (
          <Heading
            variant="pageHeading"
            sx={{
              fontSize: 4,
              emailAlign: 'center',
              display: 'block',
              width: '100%',
            }}
          >
            You have successfully signed up for our newsletter!
          </Heading>
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                maxWidth: '80ch',
                width: '100%',
                mx: 'auto',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                overflowX: 'hidden',
              }}
            >
              <Box>
                <Heading variant="pageHeading" sx={{ fontSize: 4 }}>
                  Sign up for our newsletter
                </Heading>
                {/* <Paragraph>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur doloremque id at, esse est officia corporis minus omnis sint totam aperiam, quaerat cumque sed, animi aliquam quas dolor ex doloribus?</Paragraph> */}
              </Box>
              <Box sx={{ py: 7, width: ['80%', null, '60%'], mx: 'auto' }}>
                <InputGroup
                  name="email"
                  label="Email"
                  options={{
                    required: {
                      value: !phoneSet,
                      message: 'You must specify either email or phone!',
                    },
                  }}
                  sx={{ width: '100%' }}
                  showLabel
                />

                {/* <ReactPhoneInput
                  control={control}
                  name="phone"
                  label="Phone"
                  sx={{ my: 4 }}
                  options={{
                    required: {
                      value: !emailSet,
                      message: 'You must specify either email or phone!',
                    },
                  }}
                /> */}
                <InputGroup
                  name="phone"
                  label="Phone"
                  type="email"
                  type="tel"
                  onChange={e => {
                    const formatted = formatPhoneNumber(e.target.value)
                    e.target.value = formatted
                  }}
                  options={{
                    required: {
                      value: !emailSet,
                      message: 'You must specify either email or phone!',
                    },
                  }}
                  showLabel
                  sx={{ width: '100%', my: 4 }}
                />
                <Flex sx={{ justifyContent: 'center' }}>
                  <Box sx={{ display: 'flex', position: 'relative' }}>
                    <Button variant="tertiary" aria-disabled={isSubmitting}>
                      Submit
                    </Button>
                    {isSubmitting && <Spinner sx={{ ml: 4, position: 'absolute', right: -16 }} />}
                  </Box>
                </Flex>
              </Box>
            </form>
          </FormProvider>
        )}
      </Container>
      {/* </div> */}
    </aside>
  )
}

export default NewsletterBar
