import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Label } from 'theme-ui'
import { SwRadioSelect } from '../../../SwRadioSelect/SwRadioSelect'

const ShippingMethodRates = ({ fulfillment, onSelection }) => {
  const { t } = useTranslation()

  const [insurance, setInsurance] = useState(true)

  const { shippingMethod, shippingMethodOptions } = fulfillment
  if (!fulfillment?.shippingAddress?.addressID?.length) return null
  return (
    <div className=" row shippingMethodRates">
      <h5 className="h5 pt-1 pb-2 mb-3 border-bottom">{t('frontend.checkout.delivery_option')}</h5>

      <SwRadioSelect
        options={shippingMethodOptions}
        onChange={value => {
          onSelection(value)
        }}
        selectedValue={shippingMethod?.shippingMethodID}
      />
      {shippingMethodOptions?.length === 0 && (
        <div className="alert alert-info" role="alert">
          {t('frontend.checkout.fulfilment.shipping.no_eligible_options')}
        </div>
      )}

      {/* <Label sx={{ ml: 6 }}>
        <Checkbox value={insurance} onClick={() => setInsurance(!insurance)} /> Cover my shipment with insurance - $0.00
      </Label> */}
    </div>
  )
}
export { ShippingMethodRates }
