/** @jsxImportSource theme-ui */
import { Container, Flex, Box, Heading } from 'theme-ui'

import AmmoLogo from '../../assets/images/our-story/Ammoplanet-long-logo-white.svg'
import BrandPageBullets from '../../assets/images/our-story/our-brands-bullets.png'
import GuaranteeSeal from '../../assets/images/icons/Guarantee-seal.svg'

import { Layout } from '../../components'

const OurGuarantee = () => {
  return (
    <Layout>
      <Box as="section">
        <Container variant="narrow" sx={{ pt: 24 }}>
          <Flex sx={{ flexWrap: 'wrap', pb: 4, justifyContent: 'center', mb: 12 }}>
            <Box
              sx={{
                flex: ['1 1 100%', '1 1 60%'],
                pr: [0, null, null, 6],
                mb: 4,
              }}
            >
              <Heading variant="hero">Our Guarantee</Heading>
              <p>Ammo Planet is committed to their customer base. We want to ensure that you can get access to the ammo you want and from brands and organizations that are committed to your rights as a shooter. Our guarantee is to sell our customers ammo from reputable suppliers and to ensure that you get your product in a timely manner.</p>
              <p>Our other commitment is to the 2nd Amendment and ensuring that those we work with are as well. The Ammo Planet guarantee is a sign of both ends of that commitment - to quality and the freedom of all American shooters!</p>
            </Box>

            <img
              src={GuaranteeSeal}
              alt="ap guarantee seal"
              sx={{
                flex: ['1 1 100%', '1 0 387px'],
                maxWidth: '387px',
                height: 'auto',
              }}
            />
          </Flex>
        </Container>
      </Box>

      <Flex
        sx={{
          backgroundColor: '#A71E22',
          width: '100%',
          alignContent: 'center',
          justifyContent: 'center',
          py: 24,
          px: 2,
          position: 'relative',
          minHeight: 500,
        }}
      >
        <Box
          sx={{
            maxWidth: '820px',
            height: 'auto',
            width: '100%',
            position: 'relative',
            zIndex: 2,
          }}
        >
          <img src={AmmoLogo} layout="fill" alt="ammo planet logo" />
        </Box>
        <div sx={{ position: 'absolute', left: 0, bottom: 0 }}>
          <img
            src={BrandPageBullets}
            alt="bullets"
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: 'clamp(250px, 30vw, 490px)',
              height: 'auto',
              maxWidth: '430px',
              zIndex: '1',
            }}
          />
        </div>
      </Flex>
    </Layout>
  )
}

export default OurGuarantee
