/** @jsxImportSource theme-ui */
import * as React from 'react'
import { Range, getTrackBackground } from 'react-range'
import { useListingFilter } from '../../../hooks'
import { FacetHeading } from './FacetHeading'

const STEP = 50
const MIN = 0
const MAX = 200

const stepper = number => {
  var array = new Array(Math.floor(number / 50)).fill(50).concat(number % 50)
  return array
}

const RangeFilter = ({ filter, updateAttribute, facetIdentifier = '', facetKey = '', rtl }) => {
  const [values, setValues] = React.useState([0, 200])
  const { searchTerm, setSearchTerm, searchResults, appliedFilters } = useListingFilter(filter.options, filter.selectType, facetKey)

  const [min, setMin] = React.useState(MIN)
  const [max, setMax] = React.useState(MAX)

  const handleRangeChange = values => {
    if (values[0] === MIN && values[1] === MAX) {
      updateAttribute({ filterName: facetKey, name: [] })
      return false
    }

    let name = ''
    if (values[0] === MIN) {
      name += `<${STEP},`
    } else {
      name += `${values[0]}`
    }

    const steps = stepper(values[1] - values[0])

    let lastValue = parseInt(values[0])
    for (let i = 0; i < steps.length; i++) {
      const element = steps[i]
      if (element === 0) {
        continue
      }
      let amount = lastValue + element
      if (amount > STEP) {
        name += lastValue > values[0] ? `${lastValue}-${amount},` : `-${amount},`
      }
      lastValue += element
    }

    if (values[1] === MAX) {
      name += '>200'
    } else {
      name = name.substring(0, name.length - 1)
    }

    const filters = name.split(',')

    updateAttribute({ name: filters, filterName: facetKey })
  }

  React.useEffect(() => {
    let values = []
    for (let i = 0; i < appliedFilters.length; i++) {
      let element = appliedFilters[i]
      element = element.split('-')
      values.push(...element)
    }

    if (values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        const element = values[i]
        values[i] = parseInt(element.replace('>', '').replace('<', ''))
      }

      const min = Math.min(...values)
      const max = Math.max(...values)

      setMin(min)
      setMax(max)
    }
  }, [appliedFilters])

  React.useEffect(() => {
    setValues([min, max])
  }, [min, max])

  return (
    <div className="filter-list-container mt-4">
      <FacetHeading name={'Price Range'} value={facetKey} />

      <div className={`filter-items-list pt-2 ps-2 pe-3 listingFilter ${facetKey}-list`} style={{ maxHeight: '12rem', overflowY: 'auto', overflowX: 'hidden' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
          sx={{
            my: 6,
            mx: 4,
          }}
        >
          <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            rtl={rtl}
            onChange={values => setValues(values)}
            onFinalChange={handleRangeChange}
            allowOverlap={false}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: '36px',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: '5px',
                    width: '100%',
                    borderRadius: '4px',
                    background: getTrackBackground({
                      values,
                      colors: ['#ccc', '#FAA642', '#ccc'],
                      min: MIN,
                      max: MAX,
                      rtl,
                    }),
                    alignSelf: 'center',
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ index, props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '42px',
                  width: '42px',
                  borderRadius: '4px',
                  backgroundColor: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 2px 6px #AAA',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-28px',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                    padding: '4px',
                    borderRadius: '4px',
                  }}
                  sx={{
                    backgroundColor: 'primary',
                  }}
                >
                  ${values[index]}
                  {values[index] === MAX ? '+' : ''}
                </div>
                <div
                  style={{
                    height: '16px',
                    width: '5px',
                    backgroundColor: isDragged ? 'primary' : '#CCC',
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default RangeFilter
