/** @jsxImportSource theme-ui */
import { Box, Heading } from 'theme-ui'
import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ropTypes from 'prop-types'
import { useGetEntity } from '../../hooks'

import LINKS from '../../navigation.json'

const AccordionButton = ({ item, id, closeMenu }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [boxHeight, setBoxHeight] = useState('0px')
  const boxRef = useRef(null)

  // const [categoryListRequest, setCategoryListRequest] = useGetEntity()

  // useEffect(() => {
  //   setCategoryListRequest({ ...categoryListRequest, data: [], isFetching: true, isLoaded: false, entity: 'category', params: { 'f:allowProductAssignmentFlag': true, 'p:show': 250, includeSettings: true }, makeRequest: true })
  // }, [])

  // useEffect(() => {
  //   console.log('Category list request', categoryListRequest)
  // }, [categoryListRequest])

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
    setBoxHeight(isOpen ? '0px' : `${boxRef?.current?.scrollHeight}px`)
  }

  return (
    <>
      <button
        id={`button-${id}`}
        type="button"
        onClick={toggleAccordion}
        aria-controls={`section-${id}`}
        aria-expanded={isOpen}
        sx={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderColor: '#fff',
          borderRadius: 0,
          borderStyle: 'solid',
          borderWidth: '1px',
          display: 'flex',
          justifyContent: 'space-between',
          paddingBlock: ['12px', null, '8px'],
          paddingInline: '12px',
          whiteSpace: 'nowrap',
          width: '100%',
          ':hover': {
            backgroundColor: [null, null, '#eee'],
            borderColor: [null, null, '#ccc'],
            '& ~ div': {
              display: [null, null, 'flex'],
            },
          },
        }}
      >
        <span sx={{ color: '#26303A', textTransform: 'capitalize' }}>{item.mainLinkText}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="48"
          viewBox="0 0 30 48"
          fill="none"
          sx={{
            height: 16,
            width: 10,
            marginInlineStart: 3,
            flexShrink: 0,
            transform: [isOpen ? 'rotate(-90deg)' : 'rotate(90deg)', null, 'rotate(0deg)'],
            transition: 'transform 0.3s ease',
          }}
        >
          <path d="M0 42.36L18.32 24L0 5.64L5.64 0L29.64 24L5.64 48L0 42.36Z" fill="#26303A" />
        </svg>
      </button>

      <Box
        id={`section-${id}`}
        aria-labelledby={`button-${id}`}
        role="region"
        ref={boxRef}
        sx={{
          backgroundColor: 'white',
          border: [null, null, '1px solid #ccc'],
          display: [null, null, 'none'],
          flexDirection: [null, null, 'column'],
          height: [boxHeight, null, 'auto'],
          left: [null, null, '255px'],
          minHeight: [null, null, 'calc(100% + 2px)'],
          overflow: 'hidden',
          paddingInline: [null, null, '1rem'],
          paddingBlockStart: [null, null, '1rem'],
          paddingBlockEnd: [null, null, '2rem'],
          position: [null, null, 'absolute'],
          top: [null, null, '-1px'],
          transition: 'height 0.3s ease',
          width: [null, null, '500px'],
          ':hover': {
            display: 'flex',
          },
        }}
      >
        <Heading
          as="h1"
          sx={{
            display: ['none', null, 'block'],
            marginBlockEnd: 4,
            fontSize: [4, 4],
            ':hover': { textDecoration: 'underline' },
          }}
        >
          <Link
            to={item.mainLinkUrl ? item.mainLinkUrl : '#'}
            onClick={closeMenu}
            sx={{
              fontSize: 4,
              color: 'text',
            }}
          >
            {item.mainLinkText}
          </Link>
        </Heading>

        <ul
          sx={{
            backgroundColor: ['#eee', null, 'transparent'],
            columnGap: '0.75rem',
            display: 'grid',
            gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
            listStyle: 'none',
            paddingBlock: ['12px', null, '0px'],
            paddingInline: '12px',
            rowGap: '0.75rem',
          }}
        >
          {item.subLinks.map((link, index) => (
            <li key={`sub-${index}`}>
              <Link
                to={link.url ? link.url : '#'}
                onClick={closeMenu}
                sx={{
                  color: 'text',
                  ':hover': { textDecoration: 'underline' },
                }}
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>

        {item.relatedLinks && (
          <div sx={{ marginBlockStart: 'auto' }}>
            <Heading
              as="h2"
              sx={{
                display: ['none', null, 'block'],
                fontSize: [2, 3],
                marginBlock: 6,
              }}
            >
              Related Categories
            </Heading>
            <ul
              sx={{
                columnGap: '0.75rem',
                display: ['none', null, 'grid'],
                gridTemplateColumns: 'repeat(2, 1fr)',
                listStyle: 'none',
                paddingInline: '12px',
                rowGap: '0.75rem',
              }}
            >
              {item.relatedLinks.map((link, index) => (
                <li key={`related-${index}`}>
                  <Link
                    to={link.url ? link.url : '#'}
                    onClick={closeMenu}
                    sx={{
                      color: 'text',
                      textTransform: 'capitalize',
                      ':hover': { textDecoration: 'underline' },
                    }}
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Box>
    </>
  )
}

const MegaNav = ({ data = LINKS }) => {
  const [isOpen, setIsOpen] = useState(false)

  document.addEventListener('click', e => {
    const isClickInside = document.getElementById('mega-nav-inner').contains(e.target) || document.getElementById('mega-nav-button').contains(e.target)
    if (!isClickInside) {
      setIsOpen(false)
    }
  })

  const closeMenu = () => {
    setIsOpen(false)
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <button
        type="button"
        id="mega-nav-button"
        aria-controls="mega-nav-inner"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          cursor: 'pointer',
          backgroundColor: 'transparent',
          border: 'none',
          color: 'white',
          fontFamily: 'body',
          fontSize: '14px',
          paddingInline: '12px',
          textTransform: 'uppercase',
          paddingBlock: '12px',
          ':hover': {
            textDecoration: 'underline',
          },
          '::after': {
            display: 'inline-block',
            marginLeft: '0.255em',
            verticalAlign: '0.255em',
            content: '""',
            borderTop: '0.3em solid',
            borderRight: '0.3em solid transparent',
            borderBottom: '0',
            borderLeft: '0.3em solid transparent',
          },
        }}
      >
        Shop All
      </button>

      <ul
        id="mega-nav-inner"
        aria-labelledby="mega-nav-button"
        sx={{
          backgroundColor: 'white',
          border: [null, null, '1px solid #ccc'],
          display: isOpen ? 'block' : 'none',
          listStyleType: 'none',
          minWidth: '256px',
          padding: 0,
          paddingInlineStart: 0,
          position: [null, null, 'absolute'],
          top: '53px',
          zIndex: 5,
        }}
      >
        {data.map((item, index) => (
          <li key={`nav-${index}`}>
            <AccordionButton id={`accordion-${index}`} item={item} closeMenu={closeMenu} />
          </li>
        ))}
      </ul>
    </Box>
  )
}

MegaNav.ropTypes = {
  data: ropTypes.arrayOf(
    ropTypes.shape({
      mainLinkText: ropTypes.string.isRequired,
      mainLinkUrl: ropTypes.string,
      subLinks: ropTypes.arrayOf(
        ropTypes.shape({
          text: ropTypes.string.isRequired,
          url: ropTypes.string,
        })
      ).isRequired,
      relatedLinks: ropTypes.arrayOf(
        ropTypes.shape({
          text: ropTypes.string.isRequired,
          url: ropTypes.string,
        })
      ),
    })
  ),
}

export default MegaNav
