/** @jsxImportSource theme-ui */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SimpleImage } from '..'
import { useGetBlogPosts, useUtilities } from '../../hooks'
import { useFormatDate } from '../../hooks/useFormatDate'
import { getBlogRoute } from '../../selectors/configurationSelectors'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { Button, Card, Flex, Grid, Heading, Paragraph } from 'theme-ui'

function LatestNews({ title = '2A News' }) {
  const { t } = useTranslation()
  const [formateDate] = useFormatDate()
  let [request, setRequest] = useGetBlogPosts()
  let { eventHandlerForWSIWYG } = useUtilities()
  const blogPath = useSelector(getBlogRoute)

  useEffect(() => {
    if (!request.isFetching && !request.isLoaded) {
      setRequest({ ...request, isFetching: true, isLoaded: false, params: { limit: 3 }, makeRequest: true })
    }
  }, [request, setRequest])
  if (!request.data?.items?.length) {
    return null
  }

  return (
    <Flex sx={{ flexDirection: 'column', ml: [-4, null, 0], mr: [-4, null, 0], overflow: 'hidden' }} as="section">
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          alignItems: ['flex-start', null, 'center'],
          mb: 7,
          px: [4, null, 0],
          marginInlineEnd: 5,
        }}
      >
        <Heading variant="hero" sx={{ marginInlineEnd: 10 }}>
          {title}
        </Heading>
        <Button to={'/blog'} as={Link} variant="arrow" sx={{ fontSize: 1, pt: [0, null, 2.5] }}>
          See all news
        </Button>
      </Flex>
      <Grid
        sx={{
          display: ['none', null, 'grid'],
          gridTemplateColumns: ['auto', null, 'repeat(3, 1fr)'],
          gap: 7,
          pb: 16,
        }}
      >
        {request.data?.items?.map(post => {
          return (
            <Card key={post.postTitle} sx={{ bg: 'backgroundWhite' }}>
              <header sx={{ aspectRatio: '16/10', position: 'relative' }}>
                {post.postImage ? <SimpleImage src={post.postImage.url} className="blog-image" alt={post.postTitle} /> : <div className="blog-image" />}
                {/* <Image src={post.featuredImage.url} layout="fill" objectFit="cover" /> */}
              </header>
              <div
                sx={{
                  p: 7,
                  flex: '1 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Heading as="h1" variant="cardTitle" sx={{ minHeight: '68px' }}>
                  {post.postTitle}
                </Heading>
                <Paragraph sx={{ mb: 7 }} dangerouslySetInnerHTML={{ __html: post.postSummary }} />
                <Link to={`/blog/${post.slug}`}>
                  <Button as="span" variant="arrow" sx={{ mt: 'auto' }}>
                    Read More
                  </Button>
                </Link>
              </div>
            </Card>
          )
        })}
      </Grid>
      <Swiper
        modules={[Navigation]}
        navigation={true}
        slidesPerView={1.25}
        centeredSlides={true}
        spaceBetween={20}
        sx={{
          display: ['flex', null, 'none'],
          width: '100%',
          '.swiper-wrapper': {
            py: 4,
          },
          '.swiper-button-next, .swiper-button-prev': {
            bg: '#E6E6E6',
            zIndex: 2,
            transition: 'background 0.15s, clip-path 0.1s, box-shadow 0.1s, transform 0.1s',
            "&:hover:not([aria-disabled='true'])": {
              bg: 'primary',
            },
            p: 7,
            py: 10,
            '&::after': {
              color: 'tertiary',
              fontSize: '26px',
              transform: 'scaleX(0.8)',
            },
          },
          '.swiper-button-next': {
            right: 0,
            clipPath: 'polygon(0 14px, 14px 0, 100% 0px, 100% 100%, 0 100%)',
            '&.swiper-button-disabled': {
              opacity: 0,
            },
          },
          '.swiper-button-prev': {
            left: 0,
            clipPath: 'polygon(0 0px, calc(100% - 14px) 0, 100% 14px, 100% 100%, 0 100%)',
            '&.swiper-button-disabled': {
              opacity: 0,
            },
          },
        }}
      >
        {request.data?.items?.map(post => {
          return (
            <SwiperSlide key={post.postTitle}>
              <Card key={post.postTitle} sx={{ bg: 'backgroundWhite' }}>
                <header sx={{ aspectRatio: '16/10', position: 'relative' }}>
                  {post.postImage ? <SimpleImage src={post.postImage.url} className="blog-image" alt={post.postTitle} /> : <div className="blog-image" />}
                  {/* <Image src={post.featuredImage.url} layout="fill" objectFit="cover" /> */}
                </header>
                <div
                  sx={{
                    p: 7,
                    flex: '1 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Heading as="h1" variant="cardTitle" sx={{ minHeight: '68px' }}>
                    {post.postTitle}
                  </Heading>
                  <Paragraph sx={{ mb: 7 }} dangerouslySetInnerHTML={{ __html: post.postSummary }} />
                  <Link to={`/${blogPath}/${post.slug}`}>
                    <Button as="span" variant="arrow" sx={{ mt: 'auto' }}>
                      Read More
                    </Button>
                  </Link>
                </div>
              </Card>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Flex>
  )

  return (
    <section className="content-spacer">
      <div className="container">
        <header className="section-title">
          <h2>{t('frontend.home.latest_news')}</h2>
        </header>
        <div className="row">
          {request.data?.items?.map(data => {
            return (
              <div key={data.postTitle} className="col-md-6 col-lg-4 d-flex">
                <article className="blog-card card border-0 shadow m-3 w-100">
                  <figure className="blog-figure overflow-hidden m-0">{data.postImage ? <SimpleImage src={data.postImage.url} className="blog-image" alt={data.postTitle} /> : <div className="blog-image" />}</figure>
                  <div className="card-body p-4">
                    {data.publicationDate && (
                      <p>
                        <small className="text-muted fst-italic">{formateDate(data.publicationDate)}</small>
                      </p>
                    )}
                    <h4>
                      <Link to={`/${blogPath}/${data.slug}`}>{data.postTitle}</Link>
                    </h4>
                    <p className="text-secondary" onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: data.postSummary }} />
                  </div>
                </article>
              </div>
            )
          })}
        </div>
        <div className="text-center mt-5">
          <Link className="btn btn-primary" to={`/${blogPath}`}>
            {t('frontend.home.view_all_news')}
          </Link>
        </div>
      </div>
    </section>
  )
}

export { LatestNews }
