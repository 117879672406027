/** @jsxImportSource theme-ui */
import Slider from 'react-slick'
import { ProductCard } from '..'
import { useGetProducts, useGetProductsByEntityModified, useGetProductsWithPagination } from '../../hooks'
import { useEffect, useState } from 'react'
import { sorting } from '../../utils'
import { Box, Container, Flex, Heading } from 'theme-ui'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// import 'swiper/css/navigation'

const ProductSlider = ({ children, settings, title, slidesToShow = 4, products = [] }) => {
  return (
    <Box as="section" sx={{ mt: 10, mb: 20 }}>
      <Container>
        <Flex
          sx={{
            flexDirection: ['column', null, 'row'],
            alignItems: ['flex-start', null, 'center'],
            mb: 3,
            px: [4, null, 0],
            h1: {
              marginInlineEnd: 10,
            },
          }}
        >
          <Heading as="h1" variant="hero">
            {title}
          </Heading>
          {/* {url && (
            <Link href={url} >
              <span
                sx={{
                  variant: 'buttons.arrow',
                  fontSize: 1,
                  pt: [0, null, 2.5],
                }}
              >
                {linkText ?? `Shop all ${title}`}
              </span>
            </Link>
          )} */}
          {children}
        </Flex>
      </Container>
      <Swiper
        modules={[Navigation]}
        navigation={true}
        slidesPerView={1.25}
        centeredSlides={true}
        spaceBetween={20}
        breakpoints={{
          768: {
            centeredSlides: false,
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetBefore: 30,
            slidesOffsetAfter: 15,
          },
          942: {
            centeredSlides: false,
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetBefore: 30,
            slidesOffsetAfter: 15,
          },
        }}
        sx={{
          '.swiper-wrapper': { py: 2, height: 'auto' },
          '.swiper-slide': {
            width: '340px',
            height: 'auto',
            '& article': { height: '100%' },
          },
          '.swiper-button-next, .swiper-button-prev': {
            bg: '#E6E6E6',
            zIndex: 2,
            transition: 'background 0.15s, clip-path 0.1s, box-shadow 0.1s, transform 0.1s',
            "&:hover:not([aria-disabled='true'])": {
              bg: 'primary',
            },
            p: 7,
            py: 10,
            '&::after': {
              color: 'tertiary',
              fontSize: '26px',
              transform: 'scaleX(0.8)',
            },
          },
          '.swiper-button-next': {
            right: 0,
            clipPath: 'polygon(0 14px, 14px 0, 100% 0px, 100% 100%, 0 100%)',
            '&.swiper-button-disabled': {
              opacity: 0,
            },
          },
          '.swiper-button-prev': {
            left: 0,
            clipPath: 'polygon(0 0px, calc(100% - 14px) 0, 100% 14px, 100% 100%, 0 100%)',
            '&.swiper-button-disabled': {
              opacity: 0,
            },
          },
        }}
      >
        {products.map((slide, index) => {
          return (
            <SwiperSlide key={`product-slide-${index}`}>
              <ProductCard {...slide} imageFile={slide.defaultSku_imageFile} skuID={slide.defaultSku_skuID} salePrice={slide.salePrice} listPrice={slide.listPrice} key={slide.defaultSku_skuID} />
            </SwiperSlide>
          )
        })}
        ``
      </Swiper>
    </Box>
  )
}

const ProductSliderWithList = ({ children, params = {}, settings, title, slidesToShow, productList = [] }) => {
  let [request, setRequest] = useGetProductsByEntityModified()

  useEffect(() => {
    let didCancel = false
    if (!didCancel && !request.isFetching && !request.isLoaded && productList.length) {
      setRequest({
        ...request,
        params: { ...params, includeImages: true, 'f:ProductID:in': productList.join(',') },
        entity: 'product',
        makeRequest: true,
        isFetching: true,
        isLoaded: false,
      })
    }
    return () => {
      didCancel = true
    }
  }, [request, setRequest, params, productList])
  if (!request.data.length || productList.length === 0) {
    return null
  }
  const sortedProducts = sorting(request.data, productList, 'productCode')

  return (
    <ProductSlider products={sortedProducts} settings={settings} title={title} slidesToShow={slidesToShow}>
      {children}
    </ProductSlider>
  )
}

const ProductSliderListing = ({ children, params = {}, settings, title, slidesToShow, productListingIds }) => {
  const searchParams = {
    includeResizedImages: false,
    applyStockFilter: false,
    includePagination: false,
    includePotentialFilters: false,
    pageSize: 100,
    content_id: productListingIds.join(','),
    entityName: 'Product',
    includeAttributesMetadata: true,
  }

  let data = useGetProductsWithPagination(JSON.stringify(searchParams))

  if (data.isFetching && !data.records.length > 0) {
    return null
  }

  console.log('Product slider listing', data.records)

  const products = data.records
    // .filter(x => x.skuID)
    .map(product => ({
      defaultSku_imageFile: product.sku_imageFile,
      defaultSku_skuID: product.sku_skuID,
      salePrice: product.skuPrice,
      listPrice: product.listPrice,
      key: product.product_productID,
      urlTitle: product.product_urlTitle,
      productName: product.product_productName,
      sku_calculatedQATS: product.sku_calculatedQATS,
      skuOrderMinimumQuantity: 1,
      skuOrderMaximumQuantity: 100,
    }))

  return (
    <ProductSlider products={products} settings={settings} title={title} slidesToShow={slidesToShow}>
      {children}
    </ProductSlider>
  )
}

const ProductSliderWithConfig = ({ children, params = {}, settings, title, slidesToShow }) => {
  let [request, setRequest] = useGetProductsByEntityModified()

  useEffect(() => {
    let didCancel = false
    if (!didCancel && !request.isFetching && !request.isLoaded) {
      setRequest({
        ...request,
        params: { includeImages: true, ...params },
        entity: 'product',
        makeRequest: true,
        isFetching: true,
        isLoaded: false,
      })
    }
    return () => {
      didCancel = true
    }
  }, [request, setRequest, params])
  if (!request.data.length) {
    return null
  }

  return (
    <ProductSlider products={request.data} settings={settings} title={title} slidesToShow={slidesToShow}>
      {children}
    </ProductSlider>
  )
}

export { ProductSlider, ProductSliderWithConfig, ProductSliderWithList, ProductSliderListing }
