const FAQs = () => {
  return (
    <div>
      <h1>FAQS</h1>

      <details open="">
        <summary>
          Questions &amp; Inquiries Contact<span></span>
        </summary>

        <div>
          <p>Should you need additional help from a customer services representative, or have inquiries about a product or concerns with a purchase - here are the ways you can contact us:</p>
          <h3>By Email:</h3>
          <h5>info@ammoplanet.com</h5>
          <h3>By Phone:</h3>
          <h5>844-548-5770</h5>
          <h3>By Mail:</h3>
          <h5>Ammo Planet</h5>
          <h5>PO Box 980204</h5>
          <h5>Houston, TX 77098</h5>
        </div>
      </details>
    </div>
  )
}

export default FAQs
