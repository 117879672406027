/** @jsxImportSource theme-ui */
import React from 'react'
import { Layout } from '../../components'

import { Container, Flex, Box, Heading, Button } from 'theme-ui'

import { Link } from 'react-router-dom'

import { motion, useTransform, useViewportScroll } from 'framer-motion/dist/framer-motion'

import HeroImageText from '../../assets/images/our-story/ammoplanet-red.svg'
import halfBackgroundImage from '../../assets/images/our-story/deals-background.jpg'
import sideAmmoImage from '../../assets/images/our-story/ammo-left-side.png'
import freedomBackgroundImage from '../../assets/images/our-story/Freedom-bg.jpg'

import handgunAmmo from '../../assets/images/our-story/Handgun-cropped.png'
import rifleAmmo from '../../assets/images/our-story/Riffle-cropped.png'
import shotgunAmmo from '../../assets/images/our-story/Shotgun-cropped.png'

import heroTopLeft from '../../assets/images/our-story/Top-Left-Fire.png'
import heroMiddleLeft from '../../assets/images/our-story/Left-Bullet-Time-Debris.png'
import handgunCasingTopLeft from '../../assets/images/our-story/Top-Left-Handgun-Casing.png'
import heroTopRight from '../../assets/images/our-story/Top-Right-Bullet-Time-Debris.png'
import heroBottomLeft from '../../assets/images/our-story/Left-Smoke.png'
import heroBottomRight from '../../assets/images/our-story/Bottom-Right-Fire.png'
import rifleCasingBottomRight from '../../assets/images/our-story/Bottom-Right-Rifle-Casing.png'
import rifleCasing from '../../assets/images/our-story/Top-Middle-Rifle-Casing.png'
import bulletTimeGroup from '../../assets/images/our-story/Bottom-Bullet-Time-Group.png'
import handgunCasingTopRight from '../../assets/images/our-story/Top-Right-Handgun-Casing.png'

const OurStory = () => {
  const { scrollYProgress } = useViewportScroll()

  const yPosAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, 300, 600])
  const scaleAnim = useTransform(scrollYProgress, [0, 0.5, 1], [1, 2, 3])
  const scaleAnimSlower = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1.5, 2])

  const scaleAnimFaster = useTransform(scrollYProgress, [0, 0.5, 1], [1, 3, 6])
  const yPosAnimFaster = useTransform(scrollYProgress, [0, 0.5, 1], [0, 600, 1000])

  const rotateAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, -180, -360])
  const rotateAnimFaster = useTransform(scrollYProgress, [0, 0.5, 1], [0, 600, 1000])
  const rotateAnimAlt = useTransform(scrollYProgress, [0, 0.5, 1], [0, 400, 600])

  const yNegAnimSlower = useTransform(scrollYProgress, [0, 0.5, 1], [0, -100, -200])
  const yNegAnimFaster = useTransform(scrollYProgress, [0, 0.5, 1], [0, -600, -1000])

  return (
    <>
      <Layout>
        {/* <Box sx={{ width: '100%' }}>
        <img src={HeroImage} width={1440} height={720} layout='responsive' />
      </Box> */}

        <motion.div style={{ position: 'relative', overflow: 'hidden' }}>
          {/* <Box sx={{width: '100%', height: '100%', position: 'absolute'}}>
          <img src={heroBackground} width={1440} height={720} layout={'intrinsic'} />
        </Box> */}
          <motion.div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '15vw',
              maxWidth: '466px',
              height: 'auto',
              transformOrigin: 'top left',
              scale: scaleAnimFaster,
            }}
          >
            <img src={heroTopLeft} alt="" width={'466px'} height={'344px'} sx={{ width: '100%', height: '100%' }} />
          </motion.div>

          <motion.div
            style={{
              position: 'absolute',
              bottom: '8vw',
              left: '0',
              width: '12vw',
              maxWidth: '308px',
              height: 'auto',
              display: 'block',
              transformOrigin: 'left',
            }}
          >
            <img src={heroMiddleLeft} alt="" width={308} height={665} sx={{ width: '100%', height: '100%' }} />
          </motion.div>

          <motion.div
            style={{
              position: 'absolute',
              bottom: '-8vw',
              left: '0',
              width: '15vw',
              maxWidth: '287px',
              height: 'auto',
              display: 'block',
              transformOrigin: 'left',
              scale: scaleAnimSlower,
            }}
          >
            <img src={heroBottomLeft} width={287} height={520} alt="" sx={{ width: '100%', height: '100%' }} />
          </motion.div>

          <motion.div
            style={{
              position: 'absolute',
              bottom: '-8vw',
              right: '0',
              width: '24vw',
              maxWidth: '555px',
              height: 'auto',
              display: 'block',
              transformOrigin: 'bottom right',
              scale: scaleAnim,
            }}
          >
            <img src={heroBottomRight} alt="" width={555} height={743} sx={{ width: '100%', height: '100%' }} />
          </motion.div>

          <motion.div
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              width: '24vw',
              maxWidth: '565px',
              height: 'auto',
              display: 'block',
              transformOrigin: 'top right',
            }}
          >
            <img src={heroTopRight} alt="" width={565} height={320} sx={{ width: '100%', height: '100%' }} />
          </motion.div>

          <Box
            sx={{
              width: '100%',
              minHeight: '45vw',
              paddingBottom: '12vw',
              paddingTop: '12vw',
              backgroundColor: '#E6E6E6',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              zIndex: '-1',
              overflow: 'hidden',
            }}
          >
            <motion.div
              style={{
                position: 'absolute',
                top: '30px',
                left: '45%',
                width: '11vw',
                maxWidth: '224px',
                height: 'auto',
                display: 'block',
                y: yPosAnimFaster,
                rotate: rotateAnim,
                zIndex: '3',
              }}
            >
              <img src={rifleCasing} alt="" width={224} height={215} sx={{ width: '100%', height: '100%' }} />
            </motion.div>

            <motion.div
              style={{
                position: 'absolute',
                top: '40px',
                left: '25%',
                width: '9vw',
                maxWidth: '81px',
                height: 'auto',
                display: 'block',
                y: yPosAnimFaster,
                x: yNegAnimSlower,
                rotate: rotateAnimAlt,
                zIndex: '1',
              }}
            >
              <img src={handgunCasingTopLeft} alt="" width={158} height={81} sx={{ width: '100%', height: '100%' }} />
            </motion.div>

            <motion.div
              style={{
                position: 'absolute',
                top: '-20px',
                right: '30%',
                width: '9vw',
                maxWidth: '181px',
                height: 'auto',
                display: 'block',
                y: yPosAnimFaster,
                rotate: rotateAnimFaster,
                zIndex: '1',
              }}
            >
              <img src={handgunCasingTopRight} alt="" width={181} height={121} sx={{ width: '100%', height: '100%' }} />
            </motion.div>

            <motion.div
              style={{
                position: 'absolute',
                bottom: '22%',
                right: '2%',
                width: '12vw',
                maxWidth: '241px',
                height: 'auto',
                display: 'block',
                y: yNegAnimFaster,
                x: yNegAnimFaster,
                rotate: rotateAnimAlt,
                zIndex: '2',
              }}
            >
              <img src={rifleCasingBottomRight} alt="" width={279} height={241} sx={{ width: '100%', height: '100%' }} />
            </motion.div>

            <motion.div
              style={{
                position: 'absolute',
                bottom: '0',
                left: '20.5%',
                width: '60vw',
                maxWidth: '1304px',
                height: 'auto',
                display: 'block',
                scale: scaleAnimFaster,
                transformOrigin: 'top',
              }}
            >
              <img src={bulletTimeGroup} alt="" width={279} height={241} sx={{ width: '100%', height: '100%' }} />
            </motion.div>

            <motion.div
              style={{
                zIndex: '2',
                width: '75vw',
                maxWidth: '1200px',
                paddingLeft: '35px',
                paddingRight: '35px',
                position: 'relative',
                y: yPosAnim,
              }}
            >
              <img src={HeroImageText} alt="" width={1200} height={308} sx={{ width: '100%', height: '100%' }} />
            </motion.div>
          </Box>
        </motion.div>

        <Container sx={{ py: 4 }}>
          <Flex sx={{ justifyContent: 'center' }}>
            <Box
              sx={{
                flex: '1 100%',
                textAlign: 'center',
                maxWidth: '120ch',
                py: 24,
              }}
            >
              <Heading as="h1" variant="pageHeading">
                Ammo for All
              </Heading>
              <p>When we say ammo for all, we mean it. We’ve built consistency and quality into the heart of our business because we know what it’s like to search for great ammo products and not find them. Nothing matters more to us than ensuring a consistent supply of quality ammunition so that our customers never need to look for it elsewhere.</p>
            </Box>
          </Flex>
        </Container>
        <Flex sx={{ flexWrap: 'wrap' }}>
          <Flex
            sx={{
              bg: `line`,
              color: 'textWhite',
              flex: ['1 100%', '1'],
              maxWidth: ['100%', '100%', '50%'],
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                p: 8,
                py: 24,
                pr: [undefined, undefined, undefined, 24],
                maxWidth: '80ch',
              }}
            >
              <Heading as="h1" variant="pageHeading">
                2A
              </Heading>
              <p>Our commitment to your constitutional rights as a citizen of the United States is unquestionable. We will work consistently to ensure you have access to the ammunition and accessories you need for your firearms. Ammo Planet is a through and through supporter of Second Amendment rights and the policies that protect them.</p>
              <Link to={'/guarantee'}>
                <Button as="span">Learn More</Button>
              </Link>
            </Box>
          </Flex>
          <Flex
            sx={{
              color: 'textWhite',
              flex: ['1 100%', '1'],
              maxWidth: ['100%', '100%', '50%'],
              justifyContent: 'flex-start',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                p: 8,
                py: 24,
                pl: [undefined, undefined, undefined, 24],
                maxWidth: '80ch',
                position: 'relative',
                zIndex: 2,
              }}
            >
              <Heading as="h1" variant="pageHeading">
                Fair Ammo
              </Heading>
              <p>Ammo Planet will always supply ammunition at a price that fits the market. We are committed to policies ensuring that pricing matches the vendor market position and that our products are accessible at the best value online. We work hard to guarantee that our offerings are fair and organized for availability and demand.</p>
              <Link to={'/our-brands'}>
                <Button as="span">Learn More</Button>
              </Link>
            </Box>
            <img sx={{ zIndex: 1, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }} src={halfBackgroundImage} alt="" layout="fill" />
          </Flex>
        </Flex>

        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: 14,
            flexWrap: 'wrap',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              mx: 16,
              mb: [12, null, null, 0],
            }}
          >
            <Heading as="h1" variant="pageHeading">
              Handgun
            </Heading>
            <Box sx={{ py: 4 }}>
              <img src={handgunAmmo} alt="Handgun Ammo" width={160} height={120}/>
            </Box>
            <Link to={'/handgun-ammo'}>
              <Button as="span">Shop</Button>
            </Link>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              mx: 16,
              mb: [12, null, null, 0],
            }}
          >
            <Heading as="h1" variant="pageHeading">
              Rifle
            </Heading>
            <Box sx={{ py: 4 }}>
              <img src={rifleAmmo} alt="Rifle Ammo" width={160} height={120} />
            </Box>
            <Link to={'/rifle-ammo'}>
              <Button as="span">Shop</Button>
            </Link>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              mx: 16,
              mb: [12, null, null, 0],
            }}
          >
            <Heading as="h1" variant="pageHeading">
              Shotgun
            </Heading>
            <Box sx={{ py: 4 }}>
              <img src={shotgunAmmo} alt="Shotgun Ammo" width={160} height={120} />
            </Box>
            <Link to={'/shotgun-ammo'}>
              <Button as="span">Shop</Button>
            </Link>
          </Flex>
        </Container>

        <Flex sx={{ position: 'relative', pb: 12 }}>
          <Container sx={{ py: 4, position: 'relative', zIndex: 2 }}>
            <Box sx={{ maxWidth: '80ch' }}>
              <Heading as="h1" variant="pageHeading">
                Our Ammo Selection
              </Heading>
              <p>We carry ammunition for every shooter. Looking at each aspect of gun ownership, we stock ammo for shooters with specialized requirements, for the general buyer looking to shoot for practice, and for hobby and sport shooters. Ammo Planet also caters to the shooter wanting unique ammo solutions, providing supplies and options for reloading and building out a personal supply of ammo reserves.</p>

              <Heading sx={{ mt: 12 }}>Sport</Heading>
              <p>Whether you’re a first timer at a target range or an experienced marksman, you need a solid range of ammo choices for sports shooting. Ammo Planet stocks a wide array of range-ready calibers to help you curate your shooting experience.</p>

              <Heading sx={{ mt: 12 }}>Self Defense</Heading>
              <p>When selecting a self-defense weapon, the right round is just as important as the gun itself. Our range of calibers addresses your every need, from stopping power to ease of use. Regardless of your needs or shooting experience, you’ll have ammo that can protect you. </p>

              <Heading sx={{ mt: 12 }}>Hunting</Heading>
              <p>Caliber options are everything when it comes to hunting. Using the right rounds for your gun and game is critical to your success, safety, and enjoyment. With multiple fits for every hunt, gun type, and level of experience, we take the hassle out of selecting the perfect caliber. </p>
            </Box>
          </Container>

          <div
            sx={{
              position: 'absolute',
              right: 0,
              height: '100%',
              aspectRatio: '1/1.7',
            }}
          >
            <img src={sideAmmoImage} alt="" layout="fill" objectFit="cover" sx={{ width: '100%', height: '100%', opacity: [0.5, 1] }} />
          </div>
        </Flex>
        <Flex
          sx={{
            backgroundImage: `url(${freedomBackgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            py: 32,
            position: 'relative',
            // mt: 8,
            overflow: 'hidden',
          }}
        >
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 4,
              position: 'relative',
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                color: 'textWhite',
                maxWidth: '80ch',
              }}
            >
              <Heading as="h1" variant="pageHeading">
                Freedom
              </Heading>
              <p>Gun ownership is an essential American freedom. When it comes to shooting - whether for sport, self-defense, or hunting - we believe in providing as many diverse options as possible for you to choose from. Ammo for all, freedom for all.</p>
            </Box>
          </Container>

          <img src={freedomBackgroundImage} alt="" layout="fill" objectFit="cover" sx={{ zIndex: 1, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }} />
        </Flex>
      </Layout>
    </>
  )
}

export default OurStory
