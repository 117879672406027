/** @jsxImportSource theme-ui */
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import StickyCart from '../StickyCart'

const Layout = ({ classNameList = '', children }) => {
  const siteName = useSelector(state => state.configuration.site.siteName)
  return (
    <>
      <Helmet title={siteName} />
      <div className={`${classNameList}`} sx={{ bg: 'white', flex: '1 auto' }}>
        <ToastContainer position={'bottom-right'} />
        <main sx={{ flex: '1 100%', position: 'relative', zIndex: 1, backgroundColor: 'white' }}>{children}</main>
        <StickyCart />
      </div>
    </>
  )
}

export { Layout }
