const ReturnPolicy = () => {
  return (
    <>
      <h1>Return Policy</h1>
      <h2>Ammunition</h2>
      <p>Due to regulations and safety consideration, ammunition, gun powder, primers, most liquids, and Hazardous items are not returnable.</p>
      <h2>Non-Ammunition Products</h2>
      <p>Return instructions: Go to your account, click on order history then click on return items button.</p>
      <p>Pack your return securely, in the original package if possible, and include a copy of your original invoice. Affix your shipping label to the package, making sure no other tracking labels are showing. When your package is ready give it to your mail carrier, drop it off at your nearest post office, or schedule a free at-home pick-up.</p>
    </>
  )
}

export default ReturnPolicy
