/** @jsxImportSource theme-ui */
import React from 'react'
import { Layout } from '../../components'

import { Container, Flex, Box, Heading } from 'theme-ui'

import AmmoLogo from '../../assets/images/our-story/Ammoplanet-long-logo-white.svg'
import BrandPageBullets from '../../assets/images/our-story/our-brands-bullets.png'
import OurBrandsPageImage from '../../assets/images/our-story/our-brands-bottom.jpg'

import RemingtonLogo from '../../assets/images/icons/Remington-logo.svg'
import WinchesterLogo from '../../assets/images/icons/Winchester-logo.svg'
import AguilaLogo from '../../assets/images/icons/Aguila-logo.svg'
import PMCLogo from '../../assets/images/icons/PMC-logo.svg'
import PPULogo from '../../assets/images/icons/PPU-logo.svg'
import HornadyLogo from '../../assets/images/icons/Hornady-logo.svg'
import FiocchiLogo from '../../assets/images/icons/Fiocchi-logo.svg'
import FederalLogo from '../../assets/images/icons/Federal-logo.svg'

const brands = [
  {
    name: 'Remington',
    url: '#',
    icon: RemingtonLogo,
  },
  {
    name: 'Winchester',
    url: '#',
    icon: WinchesterLogo,
  },
  {
    name: 'Aquila',
    url: '#',
    icon: AguilaLogo,
  },
  {
    name: 'PMC',
    url: '#',
    icon: PMCLogo,
  },
  {
    name: 'PPU',
    url: '#',
    icon: PPULogo,
  },
  {
    name: 'Hornady',
    url: '#',
    icon: HornadyLogo,
  },
  {
    name: 'Fiocchi',
    url: '#',
    icon: FiocchiLogo,
  },
  {
    name: 'Federal',
    url: '#',
    icon: FederalLogo,
  },
]

const OurBrands = () => {
  return (
    <Layout>
      <Container sx={{ pt: 12, pb: 4 }} variant="narrow">
        <Heading as="h1" variant="hero" sx={{}}>
          Brands we Sell
        </Heading>
        <p>Ammo Planet offers variety and volume. We curate and work with premium brands to ensure that you have options in terms of quality and price. Our effort is to ensure you can buy ammo from companies like Winchester, PMC, Aguila, and Fiocchi. We also sell target ammo at competitive pricing for those that need to restock after practice.</p>
        <p>The best ammo, at the best price. Keep your shooting going, your training primed, and always be prepared. Never forget, you are your first line of defense.</p>
      </Container>

      <Container sx={{ py: 4 }} variant="narrow">
        <Flex
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {brands.map(brand => (
            <Box key={brand.name} sx={{ flex: ['1 1 50%', '1 1 25%'], textAlign: 'center', p: 3 }}>
              <img src={brand.icon} alt={brand.name} sx={{ width: '100%', maxWidth: '185px' }} />
            </Box>
          ))}
        </Flex>
      </Container>

      <Flex
        sx={{
          backgroundColor: '#A71E22',
          width: '100%',
          alignContent: 'center',
          justifyContent: 'center',
          py: 24,
          px: 2,
          position: 'relative',
          minHeight: 400,
          my: 14,
        }}
      >
        <img src={AmmoLogo} alt="" sx={{ maxWidth: '590px', width: '100%', height: 'auto', zIndex: '2' }} />
        <div sx={{ position: 'absolute', left: 0, bottom: 0 }}>
          <img
            src={BrandPageBullets}
            alt=""
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: 'clamp(250px, 30vw, 490px)',
              height: 'auto',
              maxWidth: '430px',
              zIndex: '1',
            }}
          />
        </div>
      </Flex>

      <Container sx={{ my: 18 }} variant="narrow">
        <Heading variant="hero">Why These Brands?</Heading>
        <p>The brands we choose to sell often represent a cross section of quality, price, varied use, and the sense that the product you are purchasing is going to serve it&apos;s purpose. These brands fit that approach.</p>
      </Container>

      <div sx={{ position: 'relative' }}>
        <img
          src={OurBrandsPageImage}
          alt=""
          sx={{
            display: 'inline-block',
            width: '100%',
            height: 'auto',
            marginBottom: '-4px',
          }}
          layout="responsive"
        />
      </div>
    </Layout>
  )
}

export default OurBrands
