import { FacetSearch } from './FacetSearch'
import { FacetHeading } from './FacetHeading'
import { useListingFilter } from '../../../hooks'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import { useLocation } from 'react-use'
import { SlatwalApiService, axios } from '../../../services'
import { getCategoryRoute } from '../../../selectors'

const AttributeList = ({ appliedFilters, facetKeyName = 'name', updateAttribute, id, dealName = 'Todays Deals', categories }) => {
  const count = categories.find(x => x.name === dealName).count
  const isSelected = appliedFilters.includes(id)
  const updateAction = name => {
    updateAttribute({ name, filterName: 'content_id' })
  }
  return (
    <div className="d-flex justify-content-between">
      <div className="form-check pb-0 pe-3">
        <input className="form-check-input" type="checkbox" checked={isSelected} onChange={() => updateAction(id)} id={'test'} />
        <label className="form-check-label" onClick={() => updateAction(id)}>
          {dealName}
        </label>
      </div>
      <div className="pt-1">
        <span style={{ fontSize: 12 }} className="text-muted ml-3 text-right">
          {count}
        </span>
      </div>
    </div>
  )
}

const useDealListingFilter = () => {
  const loc = useLocation()
  const path = loc.pathname.split('/').reverse()

  const getAppliedFilters = facetKey => {
    const qs = queryString.parse(loc.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
    if (qs[facetKey]) {
      return Array.isArray(qs[facetKey]) ? qs[facetKey] : [qs[facetKey]]
    }
    return []
  }
  const appliedFilters = getAppliedFilters('content_id')

  return { appliedFilters, path }
}

const DEAL_PAGES = [
  {
    name: 'Todays Deals',
    content_id: '8a8286a3833b3a700183a56d589a0596',
  },
]

const DealFilter = ({ updateAttribute }) => {
  const { appliedFilters, path } = useDealListingFilter()

  const [dealCategories, setDealCategories] = useState([])
  const [dealCount, setDealCount] = useState(0)

  useEffect(() => {
    let source = axios.CancelToken.source()
    const fetchDealCount = async () => {
      for (let i = 0; i < DEAL_PAGES.length; i++) {
        const element = DEAL_PAGES[i]

        const data = await SlatwalApiService.products.search({ content_id: element.content_id, category_slug: path[0] }, {}, source)

        if (data.success().data.products) {
          const count = data.success().data.products.length
          const dealCats = [...dealCategories, { name: element.name, count }]
          setDealCategories(dealCats)

          if (count > 0) {
            setDealCount(dealCount + 1)
          }
        }
      }
    }
    fetchDealCount()
  }, [])

  return (
    <>
      {dealCount > 0 && (
        <div className="filter-list-container mt-4">
          <FacetHeading name="Deals" value={'deals'} />

          <div className={`filter-items-list pt-2 ps-2 pe-3 listingFilter deals-list`} style={{ maxHeight: '12rem', overflowY: 'auto', overflowX: 'hidden' }}>
            {DEAL_PAGES.map(deal => (
              <AttributeList appliedFilters={appliedFilters} id={deal.content_id} dealName={deal.name} updateAttribute={updateAttribute} categorySlug={path[0]} categories={dealCategories} />
            ))}
            {/* {searchResults &&
          searchResults.map((facet, index) => {
            if (!facet.name.includes('Deal')) return null
            if (index + 1 > updateCount) return null
            return <AttributeList appliedFilters={appliedFilters} facetKeyName={facetIdentifier} filterName={filter.name} facet={facet} key={facet.id || facet.name} facetKey={facetKey} updateAttribute={updateAttribute} />
          })} */}
            {/* {searchResults.length > updateCount && (
          <button className="link-button small mb-2" onClick={() => setUpdateCount(updateCount + filterData)}>
            {t('frontend.product.select_more')}
          </button>
        )} */}
          </div>
        </div>
      )}
    </>
  )
}

export { DealFilter }
