/** @jsxImportSource theme-ui */
import { useDispatch, useSelector } from 'react-redux'
import { HeartButton, SimpleImage, ProductPrice, Button, ProductImage, ProductForm } from '..'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getBrandRoute, getProductRoute } from '../../selectors'
import { addToCart } from '../../actions'
import { useState } from 'react'
import { Box, Card, Flex, Paragraph } from 'theme-ui'

const ProductCard = props => {
  console.log('props', props)
  const { productName, productCode, salePrice, urlTitle, brandName, imagePath, imageFile, brandUrlTitle, listPrice, images, productClearance, skuID = '', skuCode } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isFetching, setFetching] = useState(false)
  const brand = useSelector(getBrandRoute)
  const product = useSelector(getProductRoute)
  const productLink = `/${product}/${urlTitle}` + (skuID?.length ? `?skuid=${skuID}` : '')
  const useResizedImage = images && images?.length > 0

  return (
    <Card
      as="article"
      sx={{
        bg: 'backgroundWhite',
        px: [3.5, null, null, 5],
        py: [2.5, null, null, 3],
        borderRadius: 'card',
        boxShadow: 1,
        height: '100%',
      }}
    >
      <div
        sx={{
          bg: 'gray',
          p: 6,
          position: 'relative',
          width: '100%',
          // aspectRatio: '1 / 1',
          height: '100%',
          flex: '0 1 240px',
          '&:hover > div': {
            opacity: 1,
          },
        }}
      >
        <Flex
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bg: 'transparent',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'background-color 0.3s ease-in-out',
            a: {
              transition: 'opacity 0.3s ease-in-out',
              opacity: 0,
            },
            '&:hover': {
              bg: 'rgba(38,48,58,0.7)',
              a: {
                opacity: 1,
              },
            },
          }}
        >
          <a
            href={productLink}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              color: 'textWhite',
              '&:hover *': {
                color: 'textWhite',
              },
            }}
          >
            <span sx={{ variant: 'buttons.arrow', color: 'textWhite ' }}>
              Product details
              <span sx={{ variant: 'text.srOnly' }}>{productName}</span>
            </span>
          </a>
        </Flex>
        {/* <span
          className="open"
          sx={{
            position: 'absolute',
            left: 5,
            top: 5,
            opacity: 0,
            transition: 'all 0.2s',
          }}
        >
          <FiExternalLink />
        </span> */}

        <div
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            img: {
              mixBlendMode: 'darken',
            },
          }}
        >
          {/* {image && <img sx={{ p: 6, mixBlendMode: 'multiply' }} layout="fill" src={image?.url} alt={image?.name} objectFit="contain" />} */}
          {useResizedImage && <SimpleImage className="img-fluid card-image-height productImage" src={images[0]} alt={productName} type="product" />}
          {!useResizedImage && imagePath && <ProductImage customClass="img-fluid card-image-height" imageFile={imagePath} skuID={skuID} customPath="/" />}
          {!useResizedImage && imageFile && <ProductImage customClass="img-fluid card-image-height" imageFile={imageFile} skuID={skuID} />}
        </div>
        {/* 
        {hasSale(product) && (
          <div
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              bg: 'secondary',
              display: 'inline-flex',
              px: 5,
              py: 2,
              color: 'white',
              textTransform: 'uppercase',
              fontFamily: 'body',
            }}
          >
            Sale
          </div>
        )} */}
      </div>

      <Flex
        sx={{
          py: 5,
          flex: '1 auto',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Paragraph>{productName}</Paragraph>
        </Box>

        <a href={productLink} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}></a>

        <Box>
          <Flex sx={{ my: 2, justifyContent: 'space-between' }}>
            {/* Price */}
            {/* <ProductPrice product={product} sx={{ mr: 4 }} /> */}
            <ProductPrice salePrice={salePrice} listPrice={listPrice} className="d-flex" />

            {/* Stock Status */}
            {/* <ProductStock product={product} /> */}
          </Flex>

          <ProductForm sku={props} />

          {/* <Button
            disabled={isFetching}
            isLoading={isFetching}
            alt
            sx={{
              display: 'block',
              mt: 5,
              mx: 'auto',
              button: { width: '100%', px: 4 },
              position: 'relative',
              zIndex: 2,
            }}
            label={t('frontend.product.add_to_cart')}
            onClick={e => {
              e.preventDefault()
              setFetching(true)
              dispatch(addToCart(skuID)).then(() => {
                setFetching(false)
              })
            }}
          /> */}
        </Box>
      </Flex>
    </Card>
  )
}

export { ProductCard }

// <div className="card p-3 pt-2 h-100">
//   {productClearance === true && <span className="badge">{t('frontend.core.special')}</span>}
//   <HeartButton skuID={skuID} />
//   <Link to={`/${product}/${urlTitle}?skuid=${skuID}`}>
//     {useResizedImage && <SimpleImage className="img-fluid card-image-height productImage" src={images[0]} alt={productName} type="product" />}
//     {!useResizedImage && imagePath && <ProductImage customClass="img-fluid card-image-height" imageFile={imagePath} skuID={skuID} customPath="/" />}
//     {!useResizedImage && imageFile && <ProductImage customClass="img-fluid card-image-height" imageFile={imageFile} skuID={skuID} />}
//   </Link>
//   <div className="card-body">
//     <Link to={`/${brand}/${brandUrlTitle}`} className="text-capitalize mb-3" style={{ fontSize: 12 }}>
//       {brandName}
//     </Link>
//     <h2>
//       <Link to={productLink} className="product-name d-inline-block w-100">
//         {productName}
//       </Link>
//     </h2>
//     {!skuCode && productCode && <div className="product-brand">{productCode}</div>}
//     {skuCode && <div className="product-brand">{skuCode}</div>}

//     <ProductPrice salePrice={salePrice} listPrice={listPrice} className="d-flex" />
//   </div>
//   <div className="text-center card-footer border-0 bg-transparent pb-3 pt-0">
//     <Button
//       disabled={isFetching}
//       isLoading={isFetching}
//       className="btn btn-primary btn-block my-3"
//       label={t('frontend.product.add_to_cart')}
//       onClick={e => {
//         e.preventDefault()
//         setFetching(true)
//         dispatch(addToCart(skuID)).then(() => {
//           setFetching(false)
//         })
//       }}
//     />
//   </div>
// </div>
