/** @jsxImportSource theme-ui */
import { useTranslation } from 'react-i18next'
import { Button as ThemeButton } from 'theme-ui'

/**
 * Primary UI component for user interaction
 */
const Button = ({ variant, disabled = false, className = 'btn btn-primary btn-block', type = 'button', isLoading = false, children, label, onClick, alt }) => {
  const { t } = useTranslation()
  return (
    <ThemeButton variant={alt ? 'tertiary' : variant ?? 'primary'} as={type} disabled={disabled} aria-disabled={disabled} className={className} onClick={onClick}>
      {isLoading && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
      {children && children}
      {!children && t(label)}
    </ThemeButton>
  )
}
export { Button }
