/** @jsxImportSource theme-ui */
import { useSelector } from 'react-redux'
import { INSURANCE_SKU } from '../utils/insurance'

const StickyCart = () => {
  const cart = useSelector(state => state.cart)
  const { orderItems } = cart

  return (
    <div
      sx={{
        display: ['block', null, null, 'none'],
        position: 'fixed',
        right: ['12px', null, '25px'],
        bottom: ['12px', null, '25px'],
        zIndex: 100,
      }}
    >
      <a
        href="/shopping-cart"
        sx={{
          alignItems: 'center',
          backgroundColor: '#FAA642',
          bottom: '25px',
          clipPath: 'polygon(0 15px, 15px 0, 100% 0px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
          color: 'white',
          cursor: 'pointer',
          display: 'flex',
          height: '68px',
          justifyContent: 'center',
          outline: 'none',
          padding: 0,
          transition: 'clip-path 0.1s, background-color 0.1s',
          width: '68px',
          '&:hover': {
            backgroundColor: '#FAA642',
            clipPath: 'polygon(0 0px, 0px 0, 100% 0px, 100% 100%, 100% 100%, 0 100%);',
            color: 'white',
          },
        }}
      >
        {orderItems.length > 0 && (
          <span
            sx={{
              color: 'white !important',
              fontSize: '0.8125rem',
              lineHeight: '100%',
              position: 'absolute',
              right: '5px',
              top: '5px',
            }}
          >
            {orderItems.filter(x => x.sku.skuCode !== INSURANCE_SKU).length}
          </span>
        )}

        <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 16 16">
          <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
        </svg>
      </a>
    </div>
  )
}

export default StickyCart
