/** @jsxImportSource theme-ui */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart } from '../../actions'
import { useProductPrice } from '../../hooks'
import { Button, ProductQuantityInput } from '..'
import { checkInvetory } from '../../selectors'
import { toBoolean } from '../../utils'

export const ProductQuantityMessage = () => {
  const { t } = useTranslation()
  return (
    <div sx={{ my: 5 }}>
      <Button className="my-3 w-100 disabled" disabled>
        {t('frontend.checkout.stock')}
      </Button>
    </div>
  )
}

const ProductForm = ({ sku, isDisabled, isLoading, className }) => {
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const checkInvetoryFlag = useSelector(checkInvetory)
  const { showAddToCart } = useProductPrice({ salePrice: sku?.salePrice, listPrice: sku?.listPrice })
  const [quantity, setQuantity] = useState(1)

  if (checkInvetoryFlag && (!sku || sku?.calculatedQATS < 1 || sku?.stocks_calculatedQATS < 1 || sku?.sku_calculatedQATS < 1)) return <ProductQuantityMessage />

  if (toBoolean(sku?.settings?.skuAllowOrderTemplateFlag)) return null

  return (
    <div sx={{ position: 'relative', zIndex: 3 }} className={`d-flex d-flex align-items-end ${className || ''} `}>
      <form
        className="d-flex align-items-end w-100"
        onSubmit={event => {
          event.preventDefault()
        }}
      >
        {showAddToCart && (
          <div className={`form-group me-2 mb-0 w-100`}>
            <ProductQuantityInput setQuantity={setQuantity} quantity={quantity} sku={sku} stock={sku.calculatedQATS || sku.stocks_calculatedQATS} />

            <Button
              disabled={processing || isDisabled || quantity < 1 || (sku.calculatedQATS && sku.calculatedQATS < 1) || sku.sku_calculatedQATS < 1}
              isLoading={processing}
              className="my-3 w-100 disabled mb-0"
              sx={{ whiteSpace: 'nowrap' }}
              label={processing ? t('frontend.product.adding_to_cart') : t('frontend.product.add_to_cart')}
              onClick={event => {
                event.preventDefault()
                setProcessing(true)
                dispatch(addToCart(sku.skuID, quantity)).then(() => {
                  setProcessing(false)
                })
              }}
            />
          </div>
        )}
      </form>
    </div>
  )
}
export { ProductForm }
