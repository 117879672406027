/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui'
import { useState } from 'react'

const lineStyles = {
  backgroundColor: '#000',
  display: 'block',
  height: '2px',
  left: 0,
  position: 'absolute',
  width: '100%',
}

const Hamburger = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Flex sx={{ display: ['flex', null, null, 'none'], alignItems: 'center', marginBlockEnd: '1rem', justifyContent: 'center', flex: '0 0 auto', width: '25%' }}>
      <button
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded={isOpen}
        type="button"
        aria-label="Toggle navigation"
        onClick={handleOnClick}
        sx={{
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          display: 'block',
          height: '18px',
          padding: 0,
          position: 'relative',
          width: '22px',
        }}
      >
        <span
          sx={{
            top: isOpen ? '50%' : 0,
            transform: isOpen ? 'translateY(-50%) rotate(45deg)' : 'none',
            transition: 'top 0.25s ease, transform 0.25s ease',
            ...lineStyles,
          }}
        ></span>
        <span
          sx={{
            opacity: isOpen ? 0 : 1,
            top: '50%',
            transform: 'translateY(-50%)',
            transition: isOpen ? 'opacity 0.25s ease' : 'none',
            ...lineStyles,
          }}
        ></span>
        <span
          sx={{
            bottom: isOpen ? '50%' : 0,
            transform: isOpen ? 'translateY(50%) rotate(-45deg)' : 'none',
            transition: 'bottom 0.25s ease, transform 0.25s ease',
            ...lineStyles,
          }}
        ></span>
      </button>
    </Flex>
  )
}

export default Hamburger
