/** @jsxImportSource theme-ui */
import { Controller, RegisterOptions } from 'react-hook-form'
import { Label, Paragraph } from 'theme-ui'
import PhoneInput from 'react-phone-input-2'
import { Error } from './InputGroup'

const ReactPhoneInput = ({ control, name, label, className, options, hideButton = false }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={options}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
        <div
          className={className}
          sx={{
            '.phone-input': {
              width: '100%',
              borderColor: 'black',
              borderRadius: 0,
              py: 5,
            },
            '.flag-dropdown': {
              borderRadius: 0,
              borderColor: 'black',
            },
          }}
        >
          <Label sx={{ position: 'relative', m: 0 }}>
            {label && (
              <span
                sx={{
                  position: 'absolute',
                  top: -2,
                  left: hideButton ? '8px' : 12,
                  zIndex: 2,
                  backgroundColor: 'white',
                  px: 3,
                  textTransform: 'uppercase',
                }}
              >
                {options?.required.value ? `${label}*` : label}
              </span>
            )}
            <PhoneInput
              defaultMask="Phone*"
              country={'us'}
              inputStyle={{
                paddingLeft: hideButton ? '16px' : '48px',
              }}
              buttonStyle={{
                display: hideButton ? 'none' : 'block',
              }}
              disableDropdown={true}
              inputClass="phone-input"
              placeholder="Phone*"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputProps={{
                name,
              }}
            />
          </Label>
          <Error error={formState.errors[name]?.message} />
        </div>
      )}
    />
  )
}

export default ReactPhoneInput
