/** @jsxImportSource theme-ui */
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Layout, ListingPagination, SimpleImage } from '../../components'
import { useGetEntityWithPagination, useUtilities } from '../../hooks'
import { Link } from 'react-router-dom'
import { getBrandRoute } from '../../selectors'
import queryString from 'query-string'

const Manufacturer = () => {
  let { eventHandlerForWSIWYG } = useUtilities()
  const history = useHistory()
  let loc = useLocation()
  const content = useSelector(state => state.content[loc.pathname.substring(1)])
  const brandRoute = useSelector(getBrandRoute)
  const { title, contentBody } = content || {}
  const { maxCount } = useSelector(state => state.configuration.shopByManufacturer)
  let { currentPage = 1 } = queryString.parse(loc.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
  let { isFetching, records, totalRecords, totalPages } = useGetEntityWithPagination('brand', currentPage, 2000, 'brandFeatured|desc,brandName|asc', '{"includeImages":false}')

  let last = ''

  return (
    <Layout>
      <div className="bg-light p-0 pt-3 pb-5">
        {/* {title && ( */}
        <div class="page-header pt-0 px-4">
          <div class="text-left container">
            <h1 class="display-6 text-uppercase">Shop from {records.length} brands</h1>
          </div>
        </div>
        {/* )} */}
        <div className="container bg-light box-shadow-lg rounded-lg p-5 pt-0 ">
          {!contentBody && (
            <div
              className="content-body"
              onClick={eventHandlerForWSIWYG}
              dangerouslySetInnerHTML={{
                __html: contentBody || '',
              }}
            />
          )}
          <div className="container pb-4 pb-sm-5">
            <ul className="row pt-4" sx={{ columnCount: 3, display: 'block' }}>
              {!isFetching &&
                records.length &&
                records.map(brand => {
                  let starts = null
                  if (brand.brandName.charAt(0) !== last) {
                    last = brand.brandName.charAt(0)
                    starts = last
                  }
                  // last = brand.brandName.charAt(0) === last ? null : brand.brandName.charAt(0)
                  return (
                    <>
                      {starts && <li sx={{ listStyle: 'none', m: 0, p: 0, fontWeight: 'bold', fontSize: 4, '&:not(:first-child)': { mt: 5 }, mb: 2 }}>{isNaN(last) ? last : '#'}</li>}
                      {/* <li>Starts: {starts}</li> */}
                      <li>
                        <Link className="d-block overflow-hidden rounded-lg " to={`/${brandRoute}/${brand.urlTitle}`}>
                          {brand.brandName}
                        </Link>
                      </li>
                    </>
                  )
                })}
            </ul>
          </div>
          <div className="container pb-4 pb-sm-5">
            <ListingPagination
              recordsCount={totalRecords}
              currentPage={currentPage}
              totalPages={totalPages}
              setPage={pageNumber => {
                history.push({
                  pathname: loc.pathname,
                  search: `?currentPage=${pageNumber}`,
                })
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Manufacturer
