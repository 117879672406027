/** @jsxImportSource theme-ui */
import { useSelector } from 'react-redux'
import { Button, Card, Heading } from 'theme-ui'
import { Link } from 'react-router-dom'

const ImageCard = ({ card, className, fullBg = true }) => {
  const { host } = useSelector(state => state.configuration.theme)

  if (!card) return null

  const { image, title, link } = card

  const applyHost = !(image?.includes('http://') || image?.includes('https://') || image?.includes('static/media'))

  return (
    <Link to={link} passHref sx={{ '&:hover *': { color: 'inherit' } }}>
      <Card
        as="article"
        sx={{
          bg: '#E6E6E6',

          aspectRatio: image ? '0.82 / 1' : undefined,
          width: '100%',
          position: 'relative',
        }}
        variant="image"
        className={className}
      >
        {image && <img src={applyHost ? `${host}${image}` : image} alt="" layout="fill" sx={{ zIndex: 1, position: 'absolute', top: 0, bottom: 0, objectFit: 'cover' }} />}
        <div sx={{ position: 'relative', textAlign: 'center', zIndex: 2 }}>
          <Heading
            as="h1"
            sx={{
              color: fullBg ? 'white !important' : 'text !important',
              textTransform: 'uppercase',
              fontSize: [3, null, null, 4],
              fontWeight: fullBg ? 'bold' : 'thin',
            }}
          >
            {title}
          </Heading>

          <Button variant="white" as="span" sx={{ color: 'tertiary', mt: 4 }}>
            Shop
          </Button>
        </div>
      </Card>
    </Link>
  )
}

export default ImageCard
