import { useTranslation } from 'react-i18next'
import ContentLoader from 'react-content-loader'
import { FacetFilter } from './Filters/FacetFilter'

import { useFilterFormater } from '../../hooks'
import { SearchBar } from '..'
import { DealFilter } from './Filters/DealFilter'
import RangeFilter from './Filters/RangeFilter.jsx'
import { useLocation } from 'react-use'

import InventoryFilter from './Filters/InventoryFilter'

const FilterLoader = props => (
  <ContentLoader speed={2} viewBox="0 0 400 200" className="listingSidebarLoader" {...props}>
    <rect x="25" y="15" rx="5" ry="5" width="350" height="20" />
    <rect x="25" y="45" rx="5" ry="5" width="350" height="10" />
    <rect x="25" y="60" rx="5" ry="5" width="350" height="10" />
    <rect x="26" y="75" rx="5" ry="5" width="350" height="10" />
    <rect x="27" y="107" rx="5" ry="5" width="350" height="20" />
    <rect x="26" y="135" rx="5" ry="5" width="350" height="10" />
    <rect x="26" y="150" rx="5" ry="5" width="350" height="10" />
    <rect x="27" y="165" rx="5" ry="5" width="350" height="10" />
  </ContentLoader>
)

const facetListingOrder = ['caliberSpec', 'gaugeSpec', 'applicationSpec', 'bulletTypeSpec', 'shotSizeSpec', 'grainWeightSpec', 'shotWeightSpec', 'leadFreeSpec', 'caseTypeSpec', 'shotTypeSpec', 'shellLengthSpec', 'hullMaterial', 'magnificationSpec', 'minimumMagnificationSpec', 'maximumMagnificationSpec', 'tubeDiameterSpec', 'reticleTypeSpec', 'reticleFocalPlaneSpec', 'batteryTypeSpec', 'compatibleManufactureSpec', 'muzzleVelocitySpec', 'typeSpec', 'handSpec', 'nrrRatingSpec', 'fiberOpticSpec', 'focusSystemSpec', 'objectiveLensDiameterSpec', 'piecesSpec', 'ringQuantitySpec', 'finishSpec', 'materialSpec', 'warrantySpec']

const fixOrderOfAttributes = attributes => {
  let returnAttributes = []
  for (const key in attributes?.subFacets) {
    if (Object.hasOwnProperty.call(attributes?.subFacets, key)) {
      const element = attributes?.subFacets[key]
      if (facetListingOrder.includes(key)) {
        returnAttributes.push(element)
      }
    }
  }
  const sorted = returnAttributes.slice().sort(function (a, b) {
    return facetListingOrder.indexOf(a.subFacetKey) - facetListingOrder.indexOf(b.subFacetKey)
  })
  return sorted
}

const ListingSidebar = ({ isFetching, hide, filtering, updateAttribute, recordsCount }) => {
  const loc = useLocation()
  const { t } = useTranslation()

  const { option, brand, attribute, category, priceRange, productType } = useFilterFormater({ ...filtering })
  if (recordsCount < 1 && !isFetching) return null

  const orderedAttributes = fixOrderOfAttributes(attribute)

  // console.log('fixedAttributes', fixedAttributes)

  return (
    <div className="col-lg-3">
      {isFetching && (
        <>
          <FilterLoader />
          <FilterLoader />
          <FilterLoader />
        </>
      )}

      {!isFetching && (
        <div className="filter-block p-4">
          <h4 className="border-bottom pb-2 mb-3">{t('frontend.product.filterBy')}</h4>
          <SearchBar />

          {!loc.pathname.includes('deals') && <DealFilter updateAttribute={updateAttribute} />}

          {/* {!isFetching &&
            category &&
            category.options &&
            category.options.length > 0 &&
            !hide.includes(category.facetKey) &&
            [category].map(filter => {
              console.log('DealFilter', filter)
              return <DealFilter filter={filter} facetIdentifier="slug" facetKey={`category_slug`} key={`category_slug`} updateAttribute={updateAttribute} />
            })} */}

          <InventoryFilter updateAttribute={updateAttribute} />

          {!isFetching &&
            category &&
            category.options &&
            category.options.length > 0 &&
            !hide.includes(category.facetKey) &&
            [category].map(filter => {
              return <FacetFilter filter={filter} facetIdentifier="slug" facetKey={`category_slug`} key={`category_slug`} updateAttribute={updateAttribute} />
            })}

          {/* {productType &&
            productType.options &&
            productType.options.length > 0 &&
            !hide.includes(productType.facetKey) &&
            [productType].map(filter => {
              return <FacetFilter filter={filter} facetIdentifier="slug" facetKey={`productType_slug`} key={`productType_slug`} updateAttribute={updateAttribute} />
            })} */}

          {brand &&
            brand !== {} &&
            !hide.includes(brand.facetKey) &&
            [brand].map(filter => {
              return <FacetFilter filter={filter} facetIdentifier="slug" facetKey={`brand_slug`} key={`brand`} updateAttribute={updateAttribute} />
            })}

          {orderedAttributes.length > 0 &&
            orderedAttributes.map((filter, index) => {
              return <FacetFilter filter={filter} facetIdentifier="name" facetKey={`attribute_${filter.subFacetKey}`} key={`attribute_${filter.subFacetKey}`} updateAttribute={updateAttribute} />
            })}

          {/* {attribute &&
            attribute.subFacets &&
            Object.keys(attribute.subFacets).map(facetKey => {
              return [attribute.subFacets[facetKey]].map(filter => {
                return <FacetFilter filter={filter} facetIdentifier="name" facetKey={`attribute_${facetKey}`} key={`attribute_${facetKey}`} updateAttribute={updateAttribute} />
              })
            })} */}

          {option &&
            option.subFacets &&
            Object.keys(option.subFacets).map(facetKey => {
              return [option.subFacets[facetKey]].map(filter => {
                return <FacetFilter filter={filter} facetIdentifier="name" facetKey={`option_${facetKey}`} key={`option_${facetKey}`} updateAttribute={updateAttribute} />
              })
            })}

          {priceRange &&
            priceRange !== {} &&
            [priceRange].map(filter => {
              return <RangeFilter filter={filter} facetIdentifier="value" facetKey={`priceRange`} key={`priceRange`} updateAttribute={updateAttribute} />
              // return <FacetFilter filter={filter} facetIdentifier="value" facetKey={`priceRange`} key={`priceRange`} updateAttribute={updateAttribute} />
            })}
        </div>
      )}
    </div>
  )
}

export { ListingSidebar }
