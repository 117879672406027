import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import { useLocation } from 'react-use'
import { FacetHeading } from './FacetHeading'
import { useEffect } from 'react'

const InventoryFilter = ({ updateAttribute }) => {
  const loc = useLocation()
  const { t } = useTranslation()

  const getAppliedFilters = facetKey => {
    const qs = queryString.parse(loc.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
    if (qs[facetKey]) {
      return Array.isArray(qs[facetKey]) ? qs[facetKey] : [qs[facetKey]]
    }
    return []
  }
  const appliedFilters = getAppliedFilters('applyStockFilter')
  const isSelected = appliedFilters.includes('true')

  // useEffect(() => {
  //   if (!isSelected) updateAttribute({ name: 'true', filterName: 'applyStockFilter' })
  //   // updateAttribute({ name: 'true', filterName: 'applyStockFilter' })
  // }, [isSelected])

  return (
    <div className="filter-list-container mt-4">
      {/* <FacetHeading name="Inventory" /> */}

      <div className={`filter-items-list pt-2 ps-2 pe-3 listingFilter`} style={{ maxHeight: '12rem', overflowY: 'auto', overflowX: 'hidden' }}>
        <div className="d-flex justify-content-between">
          <div className="form-check pb-0 pe-3">
            <input className="form-check-input" type="checkbox" checked={isSelected} onChange={() => updateAttribute({ name: 'true', filterName: 'applyStockFilter' })} id={''} />
            <label className="form-check-label" onClick={() => updateAttribute({ name: 'true', filterName: 'applyStockFilter' })}>
              {t('frontend.product.filters.hideOutOfStock')}
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}
export default InventoryFilter
