import { BreadCrumb } from '../'

const PageHeader = ({ title, subHeading, children, ...props }) => {
  return (
    <div className="page-header pt-4 px-4">
      <div className="text-left container">
        <h1 className="display-6 text-uppercase">{title}</h1>
        {subHeading && <p className="lead mb-0">{subHeading}</p>}
      </div>

      <div className="container">
        <BreadCrumb {...props} />
      </div>
    </div>
  )
}

export { PageHeader }
