const ShippingPolicy = () => {
  return (
    <>
      <h1>Shipping Policy</h1>
      <p>Our commitment is to ship your order the same day when it is placed by 3 pm CST, Monday - Friday, however our typical ship time is within 2-3 business days given high demand.</p>
      <p>We only ship orders to addresses within the U.S.</p>
      <p>Shipping options and rates may differ and will be displayed at checkout.</p>
      <p>We currently do not ship to the following locations, because of various state and local laws:</p>
      <ol>
        <li>Alaska</li>
        <li>California</li>
        <li>Connecticut</li>
        <li>Hawaii</li>
        <li>Illinois</li>
        <li>Massachusetts</li>
        <li>New Jersey</li>
        <li>New York</li>
        <li>District of Columbia</li>
      </ol>
    </>
  )
}

export default ShippingPolicy
