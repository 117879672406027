/** @jsxImportSource theme-ui */
import { RegisterOptions, useFormContext } from 'react-hook-form'
import { Input, InputProps, Label } from 'theme-ui'
import 'react-phone-input-2/lib/style.css'

export const Error = ({ error, className }) => {
  if (!error) return null
  return (
    <span
      className={className}
      sx={{
        bg: 'warning',
        display: 'block',
        color: 'white',
        px: 4,
        py: 2,
        mt: 3,
        fontFamily: 'body',
      }}
    >
      {error}
    </span>
  )
}

export const InputGroup = ({ name, label, options, showLabel, className, hideError = false, ...rest }) => {
  // if (!register) {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  // }

  return (
    <div
      sx={{
        position: 'relative',
        variant: showLabel ? 'forms.moving' : undefined,
      }}
      className={className}
    >
      <div sx={{ position: 'relative' }}>
        <Input {...register(`${name}`, options)} placeholder={label} {...rest} />
        <Label
          sx={{
            variant: !showLabel ? 'text.srOnly' : undefined,
            textTransform: 'uppercase',
          }}
          htmlFor={name}
        >
          {options?.required ? `${label}*` : `${label}`}
        </Label>
      </div>
      {!hideError && <Error error={errors[name]?.message} />}
    </div>
  )
}

export default InputGroup
