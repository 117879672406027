import { Layout, ListingToolBar, ListingSidebar, ListingPagination, ListingGrid, PageHeader, CategoryList } from '../../components'
import { useHistory, useLocation, useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useBasicPage, useCategory, useListing } from '../../hooks'
import { useEffect, useState } from 'react'

const DealsListing = ({ id }) => {
  const [preFilter] = useState({
    content_id: id,
  })

  const { records, isFetching, potentialFilters, total, totalPages, setSort, updateAttribute, setPage, setKeyword, params, setPageSize, pageSize } = useListing(preFilter)

  return (
    <>
      <div className="container product-listing mb-5">
        {records.length > 0 && <ListingToolBar {...potentialFilters} removeFilter={updateAttribute} setSort={setSort} setPageSize={setPageSize} pageSize={pageSize} />}
        <div className="row mt-3">
          <ListingSidebar hide={[]} isFetching={isFetching} filtering={potentialFilters} recordsCount={total} keyword={params['keyword']} setKeyword={setKeyword} updateAttribute={updateAttribute} />
          <ListingGrid isFetching={isFetching} pageRecords={records} />
        </div>
        <ListingPagination recordsCount={total} currentPage={params['currentPage']} totalPages={totalPages} setPage={setPage} />
      </div>
    </>
  )
}

const Deals = () => {
  const { id } = useParams()

  let loc = useLocation()

  const path = loc.pathname.split('/').reverse()[0].toLowerCase()
  const content = useSelector(state => state.content)
  let component = 'NotFound'
  if (!content.isFetching && content[path] && content[path]?.contentPageType) {
    component = content[path].contentPageType
  }
  if (content.isFetching) {
    component = 'BasicPage'
  }

  const pageContent = useBasicPage()

  return (
    <Layout>
      {pageContent?.content?.title && (
        <div class="page-header pt-4 px-4">
          <div class="text-left container">
            <h1 class="display-6 text-uppercase">{pageContent.content.title}</h1>
          </div>
          <div class="container">
            <div class="d-block">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  {id && (
                    <li class="breadcrumb-item">
                      <a href="/deals">Deals</a>
                    </li>
                  )}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      )}
      {!pageContent.request.isFetching && <DealsListing id={pageContent.content.contentID} />}
    </Layout>
  )
}

export default Deals
