/** @jsxImportSource theme-ui */
import { Heading, Text, Paragraph, Container, Button, Flex, Box } from 'theme-ui'

import Remington from '../assets/images/brands/remington-logo.webp'
import Winchester from '../assets/images/brands/winchester-logo.webp'
import Hornady from '../assets/images/brands/hornady-logo.webp'
import Federal from '../assets/images/brands/federal-logo.webp'
import PMC from '../assets/images/brands/pmc-ammo-logo.webp'
import Fiochhi from '../assets/images/brands/fiocchi-logo.webp'

import AmmoBrandsBackground from '../assets/images/brands/ammo-brands-background.png'

const cards = [
  {
    brand: 'Remington',
    imageSrc: Remington,
    imageWidth: 134,
    imageHeight: 27,
    link: '/brand/remington-ammunition',
  },
  {
    brand: 'Winchester',
    imageSrc: Winchester,
    imageWidth: 134,
    imageHeight: 67,
    link: '/brand/winchester',
  },
  {
    brand: 'Hornady',
    imageSrc: Hornady,
    imageWidth: 134,
    imageHeight: 31,
    link: '/brand/hornady',
  },
  {
    brand: 'Federal',
    imageSrc: Federal,
    imageWidth: 134,
    imageHeight: 21,
    link: 'brand/federal-1',
  },
  {
    brand: 'PMC Ammunition',
    imageSrc: PMC,
    imageWidth: 134,
    imageHeight: 27,
    link: '/brand/pmc',
  },
  {
    brand: 'Fiocchi Ammunition',
    imageSrc: Fiochhi,
    imageWidth: 134,
    imageHeight: 31,
    link: '/brand/fiocchi',
  },
]

const AmmoBrandsSection = () => {
  return (
    <section
      sx={{
        py: 4,
        px: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: ['column', null, 'row'],
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'black',
        bg: '#e5e5e5',
      }}
    >
      <div
        sx={{
          width: ['400px', null, '600px'],
          height: ['406px', null, '100%'],

          // maxHeight: '725px',
          // width: 'auto',
          // maxWidth: '100%',
          position: 'absolute',
          right: 0,
          bottom: '0%',
          zIndex: '1',
          overflow: 'hidden',
        }}
      >
        {/* TODO: Fix image */}
        <img src={AmmoBrandsBackground} alt="bullet and explosion" />
      </div>

      <Container sx={{ py: [24, null, 44], position: 'relative', zIndex: '2' }} variant="narrow">
        <div
          sx={{
            display: 'flex',
            flexWrap: ['wrap', null, null, 'nowrap'],
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            sx={{
              flex: '1 80%',
              pr: [0, null, 6],
            }}
          >
            <Heading as="h1" variant="hero">
              Ammo brands
            </Heading>

            <Paragraph sx={{ my: 6 }}>AmmoPlanet carries a huge range of calibers from leading brands like Winchester, Aguila, Remington, and more. We have a no-limits approach when it comes to variety, and we’re committed to selling top quality products at the best possible value. So get .223/5.56, 9mm, .45, and more at the price you want.</Paragraph>
            <Box sx={{ filter: 'drop-shadow(0px 4px 4px rgba(0,0,0,0.15))' }}>
              {/* <Link href={'/brands'} passHref> */}
              <Button
                as="a"
                href="/brands"
                sx={{
                  alignSelf: 'flex-start',
                  variant: 'buttons.primary',
                }}
              >
                Shop All Brands
              </Button>
              {/* </Link> */}
            </Box>
          </div>
          <ul
            sx={{
              p: '0px',
              mt: [8, null, null, 0],
              display: 'grid',
              gap: 3,
              gridTemplateColumns: ['repeat(2, 1fr)', null, null, 'repeat(3, 1fr)'],
              listStyle: 'none',
              width: '100%',
            }}
          >
            {cards.map(({ brand, imageSrc, imageWidth, imageHeight, link }) => (
              <li key={brand} sx={{ width: '100%' }}>
                {/* <Link href="/" passHref> */}
                <a
                  href={link}
                  sx={{
                    textDecoration: 'none',
                    outline: 'none',
                    '&:hover': {
                      '.brand-link-text': { borderBottomColor: 'primary' },
                    },
                  }}
                >
                  <Flex
                    sx={{
                      px: 2,
                      py: 4,
                      bg: 'gray',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '183px',
                      boxShadow: 1,
                      borderRadius: 'card',
                    }}
                  >
                    <div
                      sx={{
                        width: ['80%', null, null, 120],
                        flex: '0 1 60px',
                        height: '60px',
                        maxWidth: '200px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBlock: 'auto',
                        img: {
                          objectFit: 'contain',
                        },
                      }}
                    >
                      <img src={imageSrc} alt={`${brand} logo`} layout="fill" sx={{ maxWidth: '100%' }} />
                    </div>
                    <Text className="brand-link-text" sx={{ variant: 'buttons.arrow' }}>
                      Shop
                    </Text>
                  </Flex>
                </a>
                {/* </Link> */}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}

export default AmmoBrandsSection
