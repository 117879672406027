/** @jsxImportSource theme-ui */
import React from 'react'
import { useSelector } from 'react-redux'
import { ProductSliderWithConfig, Layout, LatestNews } from '../../components'
import { useTranslation } from 'react-i18next'
import { Button, Container, Paragraph } from 'theme-ui'
import ImageHero from '../../components/ImageHero'
import AmmoBrandsSection from '../../components/AmmoBrandsSections'
import AccessoriesSection from '../../components/AccessoriesSection'
import { Link } from 'react-router-dom'
import ColorSection from '../../components/ColorSection'
import CategoryNavigation from '../../components/CategoryNavigation'
import { ProductSliderListing } from '../../components/ProductSlider/ProductSlider'

function Home() {
  const { home = {} } = useSelector(state => state.content)
  const { callToAction, popularProducts, contentColumns, slider } = home

  const { t } = useTranslation()
  return (
    <Layout>
      <ImageHero />

      <CategoryNavigation />

      {/* <ProductSliderWithConfig
        title={popularProducts?.title || t('frontend.home.popular_products')}
        params={{
          'f:publishedFlag': 1,
          'f:productFeaturedFlag': 1,
        }}
      /> */}

      <ProductSliderListing
        title={popularProducts?.title || t('frontend.home.popular_products')}
        params={{
          'f:publishedFlag': 1,
        }}
        productListingIds={['8a82c6f285f05a91018605a108cb36ec']}
      />
      <ProductSliderListing
        title="Deals"
        params={{
          'f:publishedFlag': 1,
        }}
        productListingIds={['8a8286a3833b3a700183a56d589a0596']}
      />
      <ProductSliderListing
        title="Just Added"
        params={{
          'f:publishedFlag': 1,
        }}
        productListingIds={['8a82c6f285f05a91018605a1c26836f0']}
      />
      {/* <ProductSliderWithConfig
        title="Just Added"
        params={{
          'f:publishedFlag': 1,
          orderBy: 'createdDateTime|desc',
          'p:show': 8,
          // 'f:productFeaturedFlag': 1,
        }}
      /> */}
      <AmmoBrandsSection />
      {/* <AccessoriesSection /> */}

      <ColorSection title="About Ammo Planet Ammunition">
        <Paragraph sx={{ my: 6 }}>Our vision is simple: to ensure our customers that they can always access the rounds they need for their firearms. As strong supporters of 2nd Amendment rights, we stand for the ability of any American to purchase a wide range of products for sport, hunting, and self-defense. Our core goal is to guarantee every buyer has access to what they need, when they need it.</Paragraph>

        <Button as={Link} to="/our-story" variant="white">
          Read Our Story
        </Button>
      </ColorSection>

      <Container variant="narrow" sx={{ mt: 24 }}>
        <LatestNews />
      </Container>

      {/* 



      <ColorSection title="About Ammo Planet Ammunition">
        <Paragraph sx={{ my: 6 }}>Our vision is simple: to ensure our customers that they can always access the rounds they need for their firearms. As strong supporters of 2nd Amendment rights, we stand for the ability of any American to purchase a wide range of products for sport, hunting, and self-defense. Our core goal is to guarantee every buyer has access to what they need, when they need it.</Paragraph>

        <Button as={Link} to="/our-story" variant="white">
          Read Our Story
        </Button>
      </ColorSection> */}
      {/* <ContentSlider slider={slider} />

      <section className="content-spacer">
        <ProductSliderWithConfig
          title={popularProducts?.title || t('frontend.home.popular_products')}
          params={{
            'f:publishedFlag': 1,
            'f:productFeaturedFlag': 1,
          }}
        />
      </section>
      {contentColumns?.columns?.length > 0 && (
        <ContentColumns title={contentColumns.title}>
          <div className="row justify-content-center">
            {contentColumns.columns.map((column, idx) => {
              return (
                <div key={idx} className={`col-lg-${12 / contentColumns.columns.length} pr-4-lg`}>
                  <ContentBlock {...column} />
                </div>
              )
            })}
          </div>
        </ContentColumns>
      )}

      <BrandSlider params={{ 'f:brandFeatured': 1 }} />
      */}
      {/* <ActionBanner data={callToAction} /> */}
    </Layout>
  )
}

export default Home
