/** @jsxImportSource theme-ui */
import React from 'react'
import { useSelector } from 'react-redux'
import { SignUpForm } from '..'
import styles from './Footer.module.css'
import { useUtilities } from '../../hooks'
import { useTranslation } from 'react-i18next'
import NewsletterBar from '../NewsletterBar'
import { Box, Container, Flex, Heading } from 'theme-ui'
import SocialList from './SocialList'

import Logo from '../../assets/images/logo-wide.svg'

const AmmoList = [
  {
    name: 'Handgun Ammo',
    link: '/category/handgun',
  },
  {
    name: 'Rifle Ammo',
    link: '/category/rifle',
  },
  {
    name: 'Shotgun Ammo',
    link: '/category/shotgun',
  },
  {
    name: 'Popular Brands',
    link: '/brands',
  },
]

const CustomerService = [
  // {
  //   name: "Order Tracking",
  //   link: "",
  // },
  // {
  //   name: "Customer Identity",
  //   link: "",
  // },
  {
    name: 'FAQs',
    link: '/legal?page=faqs',
  },
  // {
  //   name: "Contact Us",
  //   link: "",
  // },
  {
    name: 'Return Policy',
    link: '/legal?page=return-policy',
  },
]

const ContactLinks = [
  {
    name: 'info@ammoplanet.com',
    link: 'mailto:info@ammoplanet.com',
  },
]

const AboutLinks = [
  {
    name: 'Blog',
    link: '/blog',
  },
  {
    name: 'Our Story',
    link: '/our-story',
  },
  {
    name: 'Our Brands',
    link: '/our-brands',
  },
  {
    name: 'Our Guarantee',
    link: '/guarantee',
  },
]

const LegalLinkks = [
  {
    name: 'Corporate Policy',
    link: '/legal?page=corporate-policy',
  },
  {
    name: 'Terms and Conditions',
    link: '/legal?page=terms-and-conditions',
  },
  {
    name: 'Privacy Policy',
    link: '/legal?page=privacy-policy',
  },
]

const FooterLinkList = ({ links, name, className }) => {
  // console.log("Footer link list props", props);
  return (
    <nav className={className}>
      <Heading variant="footerHeading" sx={{ mb: 5 }}>
        {name}
      </Heading>
      <ul
        sx={{
          listStyle: 'none',
          m: 0,
          p: 0,
          li: {
            my: 1,
          },
          a: { textDecoration: 'none', color: 'inherit' },
        }}
      >
        {links.map(({ name, link }) => (
          <li key={link}>
            <a href={link} sx={{ variant: 'links.footerLink' }}>
              {name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}

function Footer() {
  const formLink = useSelector(state => state.configuration.footer.formLink)
  const content = useSelector(state => state.content)
  let { eventHandlerForWSIWYG } = useUtilities()
  const footer = content['footer']
  const { t } = useTranslation()

  return (
    <>
      <NewsletterBar />
      <footer sx={{ py: 6, background: 'tertiary', color: 'white' }}>
        <Container sx={{ py: 4 }}>
          <Flex sx={{ flexWrap: 'wrap', gap: 5 }}>
            <Box sx={{ flex: ['1 100%', '1 15%'], mb: [8, null, null, 0] }}>
              <FooterLinkList name="Ammo" links={AmmoList} />
            </Box>
            <Box sx={{ flex: ['1 100%', '1 25%'], mb: [8, null, null, 0] }}>
              <FooterLinkList name="Customer Service" links={CustomerService} />
              <FooterLinkList sx={{ mt: 10 }} name="Contact Us" links={ContactLinks} />
            </Box>
            <Box sx={{ flex: ['1 100%', '1 25%'], mb: [8, null, null, 0] }}>
              <FooterLinkList name="About Ammo Planet" links={AboutLinks} />
            </Box>
            <Box sx={{ flex: ['1 100%', '1 20%'] }}>
              <Heading sx={{ mb: 5 }} variant="footerHeading">
                Connect with us
              </Heading>

              <SocialList />

              {/* <div
                sx={{
                  mt: 8,
                  // maxWidth: ["150px", "100%"],
                  // width: "100%",
                  height: [150, null, null, 250],
                  mb: [4, 0],
                  position: "relative",
                }}
              > */}
              <Box sx={{ my: 8 }}>
                <a href="/" sx={{ display: 'inline-block', cursor: 'pointer' }}>
                  <img src={Logo} alt="Ammo Planet" width={210} height={185} style={{ maxWidth: '100%' }} />
                </a>
              </Box>
            </Box>
          </Flex>
          <Flex>
            <Box>
              <p sx={{ m: 0, fontSize: 1 }}>
                &copy;{new Date().getFullYear()} Ammo Planet{'  '}|{'  '}
                {/* <Link href="/sitemap" passHref={true}> */}
                {/*  */}
                {/* </Link> */}
                {LegalLinkks.map(({ name, link }, index) => (
                  <>
                    <a href={link} sx={{ color: 'inherit', textDecoration: 'none' }}>
                      {name}
                    </a>
                    {index !== LegalLinkks.length - 1 && ' | '}
                  </>
                ))}
              </p>
            </Box>
          </Flex>
        </Container>
      </footer>
    </>
  )
}

export { Footer }
