const THRESHOLD = 10000
const STANDARD_COST = 98
const RATE_PER_DOLLAR = 0.02

export const INSURANCE_SKU = 'insurance-1'

/**
 * Calculates the insurance cost
 * @param {number} price - The price of the item
 * @returns {(number)} insurance cost
 */
export const calculateInsurance = price => {
  if (typeof price !== 'number') return 0
  if (price <= 0) return 0

  const formattedPrice = price * 100

  if (formattedPrice <= THRESHOLD) {
    return STANDARD_COST / 100
  }

  const priceToInsure = formattedPrice - STANDARD_COST
  return Math.ceil(priceToInsure * RATE_PER_DOLLAR) / 100 + 10
}
