import { darken } from '@theme-ui/color'

export const ThemeUITheme = {
  space: {
    0: '0px', // 0
    px: '1px', // 1px
    0.5: '0.125rem', // 2px
    1: '0.25rem', // 4px
    1.5: '0.375rem', // 6px
    2: '0.5rem', // 8px
    2.5: '0.625rem', // 10px
    3: '0.75rem', // 12px
    3.5: '0.875rem', // 14px
    4: '1rem', // 16px
    5: '1.25rem', // 20px
    6: '1.5rem', // 24px
    7: '1.75rem', // 28px
    8: '2rem', // 32px
    9: '2.25rem', // 36px
    10: '2.5rem', // 40px
    12: '3rem', // 48px
    14: '3.5rem', // 56px
    16: '4rem', // 64px
    18: '4.5rem', // 72px
    20: '5rem', // 80px
    24: '6rem', // 96px
    28: '7rem', // 112px
    32: '8rem', // 128px
    36: '9rem', // 144px
    40: '10rem', // 160px
    44: '11rem', // 176px
    48: '12rem', // 192px
    56: '14rem', // 224px
    60: '15rem', // 240px
    64: '16rem', // 256px
    72: '18rem', // 288px
    80: '20rem', // 320px
    96: '24rem', // 384px
  },
  sizes: {
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    half: '50%',
    full: '100%',
    screenHeight: '100vh',
    screenWidth: '100vw',
  },
  fonts: {
    body: 'Lato, sans-serif',
    heading: "'Source Sans Pro', sans-serif",
    monospace: 'monospaced',
  },
  fontSizes: [
    '0.8125rem', // 0 - 13px
    '0.875rem', // 1 - 14px
    '1rem', // 2 - 16px
    '1.125rem', // 3 - 18px
    '1.5rem', // 4 - 24px
    '2.25rem', // 5 - 36px
    '3rem', // 6 - 48px
  ],
  // breakpoints: ["640px", "768px", "996px", "1024px", "1280px"],
  fontWeights: {
    thin: 300,
    regular: 400,
    body: 400,
    medium: 500,
    semiBold: 600,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.4,
    heading: 1.4,
    footer: 1.8,
  },
  letterSpacings: ['0.15em', '0.3em'],
  colors: {
    text: '#021927',
    textWhite: '#FFF',
    backgroundWhite: '#fff',
    backgroundGray: '#F4F4F4',
    primary: '#FAA642',
    secondary: '#a71e22',
    tertiary: '#26303A',
    line: '#8ca5ba',
    muted: '#f6f6f6',
    backdrop: 'rgba(33, 42, 47, 0.85)',
    gray: 'rgb(245, 245, 245)',
    warning: '#E41E26',
    success: '#008715', //008715
  },
  shadows: ['0px 8px 12px rgba(0, 0, 0, 0.05)', '0px 8px 12px rgba(0, 0, 0, 0.15)'],
  radii: {
    small: '10px',
    medium: '20px',
    large: '15px 255px 15px / 225px 15px',
    card: '3px',
  },
  buttons: {
    primary: {
      fontFamily: 'heading',
      letterSpacing: 0,
      fontSize: [1, null, null, 2],
      bg: 'primary',
      lineHeight: '1em',
      display: 'inline-block',
      maxWith: '100%',
      px: [9, null, null, 14],
      py: [4, null, null, 5],
      borderRadius: 0,
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      clipPath: 'polygon(0 15px, 15px 0, 100% 0px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
      "&:hover:not([aria-disabled='true'])": {
        bg: darken('primary', 0.15),
        clipPath: 'polygon(0 0px, 0px 0, 100% 0px, 100% 100%, 100% 100%, 0 100%)',
      },
      transition: 'background 0.15s, clip-path 0.1s, box-shadow 0.1s, transform 0.1s',
      textTransform: 'uppercase',
      "&[aria-disabled='true']": {
        bg: 'gray',
        cursor: 'not-allowed',
        color: 'rgba(0,0,0,.6)',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      bg: 'warning',
    },
    tertiary: {
      variant: 'buttons.primary',
      bg: 'tertiary',
    },
    white: {
      variant: 'buttons.primary',
      bg: 'white',
      color: 'secondary',
      "&:hover:not([aria-disabled='true'])": {
        backgroundColor: 'white',
        clipPath: 'polygon(0 0px, 0px 0, calc(100% + 7rem) 0px, calc(100% + 7rem) calc(100% + 7rem), calc(100% + 7rem) calc(100% + 7rem), 0 calc(100% + 7rem))',
        boxShadow: '0.7rem 0.7rem 1.2rem -0.5rem rgba(0,0,0,0.6)',
        transform: 'translateY(-0.1rem)',
      },
    },
    categoryNavigation: {
      variant: 'buttons.primary',
      bg: '#E6E6E6',
      color: 'text',
      fontWeight: 'thin',
      width: '100%',
      fontSize: [3, null, null, 4],
      px: [4, null, null, 13],
      py: [5, null, null, 8],
      transition: 'all 0.2s ease-in-out',
      "&:hover:not([aria-disabled='true'])": {
        bg: 'tertiary',
        color: 'textWhite',
        clipPath: 'polygon(0 0px, 0px 0, 100% 0px, 100% 100%, 100% 100%, 0 100%)',
      },
    },
    outline: {
      userSelect: 'none',
      border: '2px solid',
      borderColor: 'textWhite',
      borderRadius: '2px',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 3,
      paddingRight: 3,
      fontFamily: 'heading',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
      cursor: 'pointer',
      color: 'textWhite',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: 'textWhite',
        color: 'text',
      },
    },
    textOnly: {
      borderRadius: 0,
      WebkitAppearance: 'none',
      color: 'text',
      backgroundColor: 'transparent',
      p: 0,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    interaction: {
      variant: 'buttons.textOnly',
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
      fontSize: 2,
      fontWeight: 'heading',
      fontFamily: 'heading',
    },
    arrow: {
      variant: 'buttons.interaction',
      fontWeight: 400,
      lineHeight: 1,
      transition: 'color 0.2s',
      borderBottomWidth: '1px',
      borderBottomColor: 'transparent',
      borderBottomStyle: 'solid',
      ':after': {
        content: "'\\00bb'",
        ml: 1,
        position: 'relative',
        top: '2px',
        fontSize: '26px',
        transition: 'margin-left 0.2s, letter-spacing 0.2s',
      },
      ':hover': {
        borderBottomColor: 'primary',
        ':after': {
          // color: "primary",
          // letterSpacing: "0.3em",
          ml: 4,
        },
      },
    },
    closeButton: {
      // pr: 6,
      bg: 'primary',
      color: 'white',
      px: 4,
      py: 4,
      borderRadius: 4,
      fontFamily: 'body',
    },
  },
  links: {
    arrowLink: {
      textDecoration: 'none',
      fontSize: [1, null, 2],
      fontFamily: 'body',
      fontWeight: 'semiBold',
      color: 'tertiary',
      textTransform: 'uppercase',
      borderBottom: '2px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
      transition: 'color 0.3s ease-in-out',
      '&:hover, &:focus': {
        borderBottomWidth: '2px',
        borderBottomColor: 'primary',
        borderBottomStyle: 'solid',
        transition: 'all 0.3s ease-in-out',
      },
    },
    navLink: {
      fontFamily: 'heading',
      fontWeight: '400',
      fontSize: [0, null, 2],
      letterSpacing: 0,
      textTransform: 'uppercase',
      textDecoration: 'none',
      color: 'textWhite',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
      transition: 'color 0.3s ease-in-out',
      '&:hover, &:focus': {
        outline: 'none',
        borderBottomColor: 'textWhite',
      },
    },
    mobileNavLink: {
      variant: 'links.navLink',
      color: 'text',
      fontSize: [3, null, 2],
      '&:hover, &:focus': {
        borderBottomColor: 'secondary',
      },
    },
    footerLink: {
      display: 'inline-block',
      fontSize: [0, null, 1],
      fontFamily: 'body',
      color: 'textWhite',
      fontWeight: 'thin',
      mb: 2,
      letterSpacing: 1.2,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
      transition: 'color 0.3s ease-in-out',
      '&:hover, &:focus': {
        outline: 'none',
        borderBottomColor: 'textWhite',
      },
    },
  },
  forms: {
    input: {
      MozAppearance: 'textfield',
      backgroundColor: 'white',
      borderColor: 'black',
      borderRadius: '2px',
      borderStyle: 'solid',
      borderWidth: '1px',
      color: 'darkGray',
      fontFamily: 'heading',
      fontSize: 1,
      fontWeight: 'body',
      lineHeight: 'equal',
      outline: 'none',
      px: 4,
      py: 3,
      transition: 'box-shadow 0.3s ease, border-color 0.3s ease',
      '::placeholder': {
        color: 'text',
        fontFamily: 'heading',
        fontSize: 1,
        fontWeight: 'body',
        letterSpacing: '0.1em',
        opacity: 1,
        textTransform: 'capitalize',
      },
      ':valid': {
        color: 'black',
      },
      ':focus': {
        borderColor: 'lightGrayBlue',
        boxShadow: '0 0 0 1px #8CA5BA',
      },
      ':invalid': {
        borderColor: 'primary',
      },
      '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
      },
    },
    select: {
      variant: 'forms.input',
      color: 'black',
      '::placeholder': {
        color: 'black',
      },
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      textIndent: '1px',
      textOverflow: '',
    },
    // select: {
    //   backgroundColor: "white",
    //   borderColor: "black",
    //   borderRadius: "2px",
    //   borderStyle: "solid",
    //   borderWidth: "1px",
    //   boxShadow: "none",
    //   fontFamily: "body",
    //   fontSize: 1,
    //   fontWeight: "body",
    //   lineHeight: "1em",
    //   outline: "none",
    //   pl: 4,
    //   pr: 8,
    //   py: 3,
    //   transition: "border-color 0.3s ease, box-shadow 0.3s ease",
    //   ":focus": {
    //     borderColor: "lightGrayBlue",
    //     boxShadow: "0 0 0 1px #8CA5BA",
    //   },
    // },
    label: {
      color: 'text',
      fontFamily: 'heading',
      fontSize: 1,
      fontWeight: 'body',
      letterSpacing: '0.1em',
      lineHeight: 'body',
      ml: '5px',
      textTransform: 'capitalize',
    },
    moving: {
      mt: 2,
      'input::placeholder': {
        opacity: 0,
      },
      ' label': {
        pointerEvents: 'none',
        position: 'absolute',
        left: 2,
        bg: 'transparent',
        display: 'inline',
        width: 'auto',
        px: 2,
        top: '50%',
        transform: 'translateY(-50%)',
        transition: 'transform 0.2s, background 0.4s',
      },
      'input:not(:placeholder-shown) ~ label ': {
        bg: 'white',
        transform: theme => `translateY(calc(-50% - ${theme.space[5]}))`,
        // top: -3,
      },
    },
    fieldset: {
      border: 'none',
      m: '0',
      p: '0',
    },
    legend: {
      color: 'darkGray',
      fontFamily: 'heading',
      fontSize: 3,
      fontWeight: 'semiBold',
      letterSpacing: '0.2em',
      textTransform: 'uppercase',
    },
  },
  cards: {
    primary: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      transition: 'box-shadow 0.2s, transform 0.2s',
      '&:hover': {
        boxShadow: '0.2rem 0.2rem 0.75rem 0.1rem rgba(0,0,0,0.15)',
        // transform: "translateY(-0.05rem)",
        transform: 'translateY(-0.2rem)',
        '.open': {
          opacity: 1,
        },
      },
    },
    image: {
      variant: 'cards.primary',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      py: 5,
      borderRadius: 5,
      overflow: 'hidden',
      '&:hover': {
        boxShadow: '0.2rem 0.2rem 0.75rem 0.1rem rgba(0,0,0,0.5)',
        transform: 'translateY(-0.2rem)',
      },
    },
  },
  text: {
    default: {
      fontFamily: 'body',
      fontWeight: 'body',
      //   fontSize: [1, null, 2],
      //   mb: [3, null, 5],
    },
    paragraph: {
      color: 'inherit',
      fontFamily: 'body',
      fontWeight: 'regular',
      lineHeight: 'body',
      fontSize: 2,
      textDecoration: 'none',
      // fontSize: [1, null, 2],
      // my: [3, null, 6],
    },
    srOnly: {
      height: 1,
      left: -10000,
      overflow: 'hidden',
      position: 'absolute',
      top: 'auto',
      width: 1,
    },
    heading: {
      letterSpacing: '0.15em',
      fontSize: [4, null, null, 5],
    },
    hero: {
      variant: 'text.heading',
      fontSize: [5, null, null, 6],
      fontWeight: 'bold',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
    categoryButton: {
      variant: 'text.heading',
      fontSize: [3, null, null, 4],
    },
    cardTitle: {
      fontFamily: 'heading',
      fontSize: [3, null, null, 4],
      mb: 3,
    },
    footerHeading: {
      variant: 'text.heading',
      fontSize: [3, null, null, 4],
      textTransform: 'uppercase',
    },
    filterHeading: {
      variant: 'text.heading',
      fontSize: [2, null, null, 3],
      textTransform: 'uppercase',
    },
    // heading: {
    //   fontFamily: 'heading',
    // },
    // heading: {
    //   letterSpacing: "0.2em",
    // },
    // pageHeading: {
    //   fontSize: 5,
    //   letterSpacing: "0.2em",
    //   textTransform: "uppercase",
    // },
    pageHeading: {
      fontSize: [4, null, null, 5],
      textTransform: 'uppercase',
      letterSpacing: 0,
    },
    subHeading: {
      fontSize: 2,
      textTransform: 'uppercase',
      letterSpacing: 0,
      fontWeight: 'heading',
    },
  },
  layout: {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      px: 4,
    },
    narrow: {
      maxWidth: '75rem',
      margin: '0 auto',
      px: [4, null, null, null, null, 0],
    },
  },
  styles: {
    // a: {
    //   fontFamily
    // },
    root: {
      body: {
        backgroundColor: 'backgroundGray',
        fontFamily: 'body',
      },
      'p,ul,li': {
        variant: 'text.paragraph',
      },
      li: {
        m: 0,
      },
      h2: {
        variant: 'text.heading',
        textTransform: 'uppercase',
        fontFamily: 'heading',
      },
      h1: {
        variant: 'text.hero',
        textTransform: 'uppercase',
        fontFamily: 'heading',
      },
    },
  },
}
