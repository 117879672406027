import { Layout, ListingToolBar, ListingSidebar, ListingPagination, ListingGrid, ListingListView } from '../../components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useListing } from '../../hooks'
import { useState } from 'react'
import { useLocation } from 'react-router'
import { Container, Flex, Heading, Paragraph, Box, Button } from 'theme-ui'
import { FormProvider, useForm } from 'react-hook-form'
import InputGroup from '../../components/InputGroup'
import ReactPhoneInput from '../../components/ReactPhoneInput'

const preFilter = {}
const GRID = 'GRID'
const LISTING = 'LISTING'

const SearchWithText = () => {
  const [viewMode] = useState(GRID)
  const loc = useLocation()
  const { t } = useTranslation()
  const siteName = useSelector(state => state.configuration.site.siteName)
  const content = useSelector(state => state.content[loc.pathname.substring(1)])
  const hide = []
  const { records, isFetching, potentialFilters, total, totalPages, setSort, updateAttribute, setPage, setKeyword, params, setPageSize, pageSize } = useListing(preFilter)

  const [success, setSuccess] = useState(false)

  const methods = useForm({
    defaultValues: {
      email: '',
      phone: '',
    },
  })
  const { control, handleSubmit, setError } = methods

  const onSubmit = async data => {
    const response = await fetch('/api/klaviyo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          console.log(data.detail)
          setError('phone', { type: 'focus', message: data.detail }, { shouldFocus: true })
        } else {
          setSuccess(true)
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  return (
    <Layout>
      <Helmet title={`${t('frontend.header.shop')} - ${siteName}`} />

      <Flex
        sx={{
          bg: 'black',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          minHeight: 400,
          mb: 8,
          position: 'relative',
        }}
      >
        <Container
          sx={{
            position: 'relative',
            zIndex: 2,
            textAlign: 'center',
            color: 'white',
            maxWidth: '90ch',
            my: 16,
          }}
        >
          <Heading>Sign up for texts from Ammoplanet</Heading>
          <Paragraph sx={{ mt: 4, mb: 6 }}>
            It&apos;s simple - enter your mobile number below. You&apos;ll get early access to restocks, hot products, specials, and more!
            {/* Also we'll get send you a discount for your birthday, but that's up */}
            {/* to you. */}
          </Paragraph>

          {success && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              width={150}
              height={150}
              viewBox="0 0 24 24"
              sx={{
                marginInline: 'auto',
                display: 'flex',
              }}
            >
              <path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" fillRule="nonzero" fill="white" />
            </svg>
          )}
          {!success && (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ReactPhoneInput
                  label="phone"
                  control={control}
                  name="phone"
                  hideButton
                  sx={{ mb: 4, mt: 8 }}
                  options={{
                    required: {
                      value: true,
                      message: 'Phone number required',
                    },
                  }}
                />

                <InputGroup
                  label="First Name (Optional)"
                  name="firstName"
                  placeholder="First Name*"
                  showLabel
                  // sx={{ mt: 4 }}
                />

                <Box sx={{ textAlign: 'center', marginBlockStart: '28px' }}>
                  <Button type="submit" variant="white" name="button">
                    subscribe
                  </Button>
                </Box>
              </form>
            </FormProvider>
          )}
        </Container>
        <div
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          <div
            sx={{
              bg: 'black',
              zIndex: 2,
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              opacity: 0.5,
            }}
          />
          <img
            // src={OurBrandsPageImage}
            sx={{
              display: 'inline-block',
              width: '100%',
              height: 'auto',
              marginBottom: '-4px',
            }}
            objectFit="cover"
            layout="fill"
          />
        </div>
      </Flex>
      <div className=" py-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-1 pr-lg-4 text-center">
            <h1 className="text-uppercase h3 mb-0 font-accent t">{content?.title || t('frontend.header.shopStore')}</h1>
          </div>
        </div>
      </div>
      <div className="container product-listing mb-5">
        <ListingToolBar hide={hide} {...potentialFilters} removeFilter={updateAttribute} setSort={setSort} recordsCount={total} pageSize={pageSize} setPageSize={setPageSize} />

        <div className="row mt-3">
          <ListingSidebar isFetching={isFetching} hide={hide} filtering={potentialFilters} recordsCount={total} keyword={params['keyword']} setKeyword={setKeyword} updateAttribute={updateAttribute} />
          {viewMode === LISTING && <ListingListView isFetching={isFetching} pageRecords={records} />}
          {viewMode === GRID && <ListingGrid isFetching={isFetching} pageRecords={records} />}
        </div>
        <ListingPagination recordsCount={total} currentPage={params['currentPage']} totalPages={totalPages} setPage={setPage} />
      </div>
    </Layout>
  )
}
export default SearchWithText
