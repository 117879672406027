/** @jsxImportSource theme-ui */
import { useDispatch } from 'react-redux'
import { Box, Flex } from 'theme-ui'
import { addInsurance } from '../../actions'
import { useFormatCurrency } from '../../hooks'

const InsuranceCard = ({ insurance, onRemoveItem }) => {
  const [formatCurrency] = useFormatCurrency({})
  const dispatch = useDispatch()

  // if (!insurance) return null

  const handleAddInsurance = () => {
    dispatch(addInsurance())
  }

  return (
    <Flex className="card" sx={{ bg: !insurance && 'rgb(204, 18, 43)', color: !insurance && 'white', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', p: 4, mb: 5 }}>
      <Box>
        Your shipment is {!insurance && <strong sx={{ textTransform: 'uppercase' }}>not</strong>} protected by our{' '}
        <a sx={{ color: 'inherit' }} href="/guarantee">
          guarantee
        </a>
        <br />
        {insurance ? (
          <span onClick={event => onRemoveItem(event)} sx={{ fontSize: '0.75em', cursor: 'pointer' }}>
            Don't want to protect your shipment? Remove it
          </span>
        ) : (
          <span onClick={event => handleAddInsurance()} sx={{ fontSize: '0.75em', cursor: 'pointer' }}>
            Want to protect your shipment? Add it
          </span>
        )}
      </Box>
      {insurance && (
        <>
          <Box>{formatCurrency(insurance.price)}</Box>
          <Box sx={{ flex: '1 100%' }}></Box>
        </>
      )}
    </Flex>
  )
}

export default InsuranceCard
