const PrivacyPolicy = () => {
  return (
    <>
      <h1>Privacy Policy</h1>
      <h1>Ammoplanet</h1>
      <p>Last updated: June 3, 2021</p>
      <ol>
        <li>Our Commitment to Your Privacy</li>
      </ol>
      <p>Ammoplanet recognizes and respects your right to privacy. We are providing this Privacy Policy to help you better understand the type of Personal Information we collect and how and why we collect or share this information as well as tell you about your rights and choices about the use of your Personal Information. This Privacy Policy governs how we collect, use, and share your Personal Information in connection with ammoplanet.com (the “Website”). If you do not agree with this Privacy Policy, please do not provide us with your Personal Information.</p>
      <p>This Privacy Policy should be read together with other documents that we provide to you, including our Terms &amp; Conditions.</p>
      <ol start="2">
        <li>Types of Data Collected</li>
      </ol>
      <p>Personal Information Personal Information is any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with you. While using our Website, we may ask you to provide us with certain Personal Information, including your:</p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Address, state, postal code, and city</li>
        <li>Bank account information in order to pay for products and/or services within the Website.</li>
        <li>Firearms Purchaser Identification Card, Firearm Owner’s Identification Card, Handgun Purchase Permit or other similar license, and in each case, its accompanying identification number, as may be required by the laws of your state.</li>
        <li>Usage Data (as defined below)</li>
      </ul>
      <p>When you pay for a product and/or a service via bank transfer, we may ask you to provide information to facilitate this transaction and to verify your identity. Such information may include, without limitation: Date of birth</p>
      <ul>
        <li>Date of Birth</li>
        <li>Passport or National ID card</li>
        <li>Bank card statement</li>
        <li>Other information linking you to an address.</li>
      </ul>
      <ol start="3">
        <li>Sources of Personal Information</li>
      </ol>
      <p>We collect and maintain Personal Information about you from the following sources:</p>
      <ul>
        <li>Communications with you, including over the telephone, in voicemails, through written materials and via e-mail and other electronic communications; and</li>
        <li>Through your use of our Website.</li>
      </ul>
      <p>For example, certain data is generated by the use of the Website or from the Website infrastructure itself (for example, data tracking the duration of your visit to our website) (“Usage Data”) and is collected automatically when you use the Websites.</p>
      <p>Usage Data includes information such as your device’s Internet Protocol address (i.e., your IP address), browser type, browser version, the pages of our Website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
      <p>When you access the Website by or through a mobile device, like a cellphone, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>
      <p>We may also collect information that your browser sends whenever you visit our Website or when you access the Website by or through a mobile device.</p>
      <p>
        <strong>Tracking Technologies and Cookies</strong>
      </p>
      <p>We also use small files that are placed on your computer or mobile device, containing the details of your browsing history on the Website and similar tracking technologies (“Cookies”) to track the activity on our Website and store certain information in order to improve and analyze our Website. You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Website.</p>
      <ol start="4">
        <li>Use of Your Personal Data</li>
      </ol>
      <p>Ammoplanet uses your Personal Information for the following purposes:</p>
      <ul>
        <li>To provide you with the items or services you have purchased through the Website;</li>
        <li>To improve and maintain our Website, including to monitor the usage of our Website;</li>
        <li>To manage your registration as a user of the Website;</li>
        <li>To contact you by email, telephone, or text message;</li>
        <li>To provide you with news, special offers and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</li>
      </ul>
      <ol start="5">
        <li>Disclosure of Personal Information</li>
      </ol>
      <p>We may share your Personal Information in the following situations:</p>
      <ul>
        <li>With Website providers: We may share your Personal Information with Website providers to monitor and analyze the use of our Website, to show advertisements to you to help support and maintain our Website, to contact you, to advertise on third party websites to you after you visited our Website or for payment processing.</li>
        <li>For business transfers: We may share or transfer your Personal Information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        <li>With affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</li>
        <li>With law enforcement: Under certain circumstances, we may be required to disclose your Personal Information by law or in response to valid requests by public authorities (e.g. a court or a government agency).</li>
        <li>Other permitted uses: We may also disclose your Personal Information in the good faith belief that such action is necessary to: comply with a legal obligation; protect and defend our rights or property; prevent or investigate possible wrongdoing in connection with the Website; protect the personal safety of users of the Website or the public; or protect against legal liability.</li>
      </ul>
      <ol start="6">
        <li>Retention of Your Personal Data</li>
      </ol>
      <p>Ammoplanet will retain your Personal Information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
      <p>Ammoplanet will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Website, or we are legally obligated to retain this data for longer time periods.</p>
      <ol start="7">
        <li>Security of Your Personal Data</li>
      </ol>
      <p>We have implemented reasonable security measures designed to protect the Personal Information that we process. These security measures are designed to prevent unauthorized access, improper use or disclosure, unauthorized modification, and unlawful destruction or accidental loss of your Personal Information. However, no method of processing data is 100% secure.</p>
      <ol start="8">
        <li>Your California Privacy Rights</li>
      </ol>
      <p>If you are a California resident, then you also have the following rights under the California Consumer Privacy Act (“CCPA”) regarding your Personal Information.</p>
      <p>A. Right to Know</p>
      <p>You have the right to know and see what Personal Information we have collected about you over the past 12 months, including:</p>
      <p>(a) The categories of Personal Information we have collected about you; (b) The categories of sources from which the Personal Information is collected; (c) The business or commercial purpose for collecting your Personal Information; (d) The categories of third parties with whom we have shared your Personal Information; and (e) The specific pieces of Personal Information we have collected about you.</p>
      <p>B. Right to Delete</p>
      <p>You have the right to request that we delete the Personal Information we have collected from you. There are a number of exceptions, however, that include, but are not limited, to when the information is necessary for us or a third party to do any of the following:</p>
      <p>(a) Complete your transaction; (b) Provide you a good or service; (c) Perform a contract between us and you; (d) Protect your security and prosecute those responsible for breaching it; (e) Fix our system in the case of a bug; (f) Protect the free speech right of you or others; (g) Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et seq.); (h) Engage in public or peer-reviewed scientific, historical, or statistical research in the public interests that adheres to all other applicable ethics and privacy laws; (i) Comply with a legal obligation; or (j) Make other internal and lawful uses of the information that are compatible with the context in which you provided it.</p>
      <p>C. Sale of Your Personal Information</p>
      <p>We do not sell your Personal Information for money or other consideration of value.</p>
      <p>More information about your rights as a California consumer may be found on California’s Department of Justice website.</p>
      <p>D. Ammoplanet Does Not Discriminate</p>
      <p>We will not discriminate against you for exercising your rights under the CCPA, including by denying service, suggesting that you will receive, or charging, different rates for services or suggesting that you will receive, or providing, a different level or quality of service to you.</p>
      <p>E. How to Exercise Your Rights</p>
      <p>To exercise any of your California access or deletion request rights, please submit a verifiable consumer request to [privacy@Ammoplanet.com] or by calling our toll-free line, [_].</p>
      <p>We will contact you to confirm receipt of your request under the CCPA and request any additional information necessary to verify your request. We verify requests by matching information provided in connection with your request to information contained in our records. You may also designate an authorized agent to make a request under the CCPA on your behalf, if you provide a signed agreement verifying such authorized agent’s authority to make requests on your behalf, and we may verify such authorized person’s identity using the procedures above.</p>
      <p>The verifiable consumer request must:</p>
      <ul>
        <li>
          <p>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or an authorized representative.</p>
        </li>
        <li>
          <p>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</p>
        </li>
      </ul>
      <p>We will not be able to respond to your request or provide you with specific pieces of Personal Information if we cannot verify your identity and authority to make the request.</p>
      <p>Our goal is to respond to any verifiable consumer request within forty-five (45) days of our receipt of such request. Please contact us at [privacy@Ammoplanet.com] with any questions about this Privacy Policy.</p>
      <ol start="9">
        <li>"Do Not Track" Policy</li>
      </ol>
      <p>Our Website does not respond to Do Not Track (“DNT”) signals.</p>
      <p>However, some third-party websites do keep track of your browsing activities. If you are visiting such websites, you can set your preferences in your web browser to inform websites that you do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of your web browser.</p>
      <ol start="10">
        <li>Children's Privacy</li>
      </ol>
      <p>Our Website does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.</p>
      <p>We may also limit how we collect, use, and store some of the information of Users between 13 and 18 years old. In some cases, this means we will be unable to provide certain functionality of the Website to these users.</p>
      <ol start="11">
        <li>Links to Other Websites</li>
      </ol>
      <p>Our Websites may contain links to other outside websites. Please be aware that we are not responsible for the content or privacy practices of such other websites. We encourage you to read the privacy statements of any other website that collects information of visitors.</p>
      <ol start="12">
        <li>Changes to This Privacy Policy</li>
      </ol>
      <p>We may revise this Privacy Policy. If we revise the Privacy Policy, we will post the updated policy on our website. Please check this Privacy Policy at ammoplanet.com frequently to ensure that you are familiar with its current content.</p>
      <ol start="13">
        <li>Contact Us</li>
      </ol>
      <p>If you have any questions about this Privacy Policy, you can contact us: By email: [info@ammoplanet.com] By visiting this page on our website: www.ammoplannet.com</p>
    </>
  )
}

export default PrivacyPolicy
