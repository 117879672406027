/** @jsxImportSource theme-ui */
import { FAQs, PrivacyPolicy, ReturnPolicy, ShippingPolicy, TermsAndConditions } from '../../content'
import { Container } from 'theme-ui'
import { useMemo } from 'react'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'

const LEGAL_LINKS = [
  {
    text: 'return policy',
    url: '/legal?page=return-policy',
  },
  {
    text: 'PRIVACY POLICY',
    url: '/legal?page=privacy-policy',
  },
  {
    text: 'SHIPPING POLICY',
    url: '/legal?page=shipping-policy',
  },
  {
    text: 'TERMS & CONDITIONS',
    url: '/legal?page=terms-and-conditions',
  },
  {
    text: 'faqs',
    url: '/legal?page=faqs',
  },
]

function UseQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const legal = () => {
  const query = UseQuery()
  const currentPage = query.get('page')

  const Content = () => {
    switch (currentPage) {
      case 'return-policy':
        return <ReturnPolicy />
      case 'privacy-policy':
        return <PrivacyPolicy />
      case 'shipping-policy':
        return <ShippingPolicy />
      case 'terms-and-conditions':
        return <TermsAndConditions />
      case 'faqs':
        return <FAQs />
      default:
        return <ReturnPolicy />
    }
  }

  return (
    <Container>
      <div sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBlock: [8, null, 12] }}>
        <aside sx={{ flex: ['0 0 100%', null, '0 0 25%'] }}>
          <h1
            sx={{
              fontFamily: "'Source Sans Pro',sans-serif",
              fontWeight: '600',
              lineHeight: '1',
              marginTop: '0px',
              textTransform: 'uppercase',
              fontSize: '1.5em',
              marginBlockStart: '0.83em',
            }}
          >
            TERMS OF USE & FAQS
          </h1>

          <hr sx={{ backgroundColor: 'var(--theme-ui-colors-primary)', borderRadius: '1px', height: '1px', marginLeft: '0px', marginRight: '0px', marginTop: '0.25rem', marginBottom: '1rem', width: '100%' }} />

          <ul
            sx={{
              listStyle: 'none',
              paddingInlineStart: 0,
            }}
          >
            {LEGAL_LINKS.map((link, index) => {
              const isActive = link.url === `/legal?page=${currentPage}`

              return (
                <li
                  key={`link-${index}`}
                  sx={{
                    '&:not(:first-of-type)': {
                      marginTop: '12px',
                    },
                  }}
                >
                  <Link
                    to={link.url}
                    sx={{
                      color: isActive ? '#a71e22' : 'text',
                      textDecoration: isActive ? 'underline' : 'none',
                      textTransform: 'uppercase',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {link.text}
                  </Link>
                </li>
              )
            })}
          </ul>
        </aside>

        <section
          className="markdown-content"
          sx={{
            flex: ['1 1 100%', null, '0 0 70%'],
          }}
        >
          <Content />
        </section>
      </div>
    </Container>
  )
}

export default legal
