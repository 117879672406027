/** @jsxImportSource theme-ui */

import { Box, Button, Container, Heading, Paragraph } from 'theme-ui'

import { Link } from 'react-router-dom'

import HeroImage from '../assets/images/v2-hero-bg.png'

const ImageHero = props => {
  return (
    <Box as="section" sx={{ position: 'relative' }}>
      <img src={HeroImage} alt="" sx={{ zIndex: 1, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }} />
      <Container
        sx={{
          color: '#fff',
          pt: 12,
          pb: 24,
          position: 'relative',
          zIndex: 2,
          maxWidth: 996,
        }}
      >
        <Box sx={{ maxWidth: '589px', pt: 8 }}>
          <span sx={{ fontSize: 4, color: '#fff', textTransform: 'uppercase', mb: -3 }}>Featured</span>
          <Heading as="h1" variant="hero" sx={{ color: '#fff', mt: 0, lineHeight: '1em', mb: 2 }}>
            Shop our Weekly Deals!
          </Heading>
          <Paragraph>Save on your favorite ammo and gear.</Paragraph>
          <Link to={'/deals'}>
            <Button variant="white" sx={{ color: 'tertiary', mt: 6, fontSize: [1, null, null, 2] }}>
              Shop Now
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

export default ImageHero
